import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IDataToSubmit, IOption } from 'entities/surveys';

import icons from 'assets/icons';

import Text from 'components/Text';

import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SelectMultipleStyles';

interface CheckboxProps {
    selected: boolean;
}

const Checkbox = styled.button<CheckboxProps>`
    min-height: 30px;
    min-width: 30px;

    border: 1px solid #A5AAB5;
    border-radius: 0px;

    margin-right: 10px;

    background-color: ${props => (props.selected ? '#021778' : 'white')};

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    padding: 0px;

    #check {
        height: 20px;
        width: 20px;
        color: white;
        position: absolute;
    }
`;

interface SelectMultipleProps {
    surveyId: string;
    questionId: string;
    question: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const SelectMultiple: FunctionComponent<SelectMultipleProps> = (props: SelectMultipleProps) => {
    const { surveyId, questionId, question, options, images, loading, changeQuestionStatus, submitAnswer } = props;

    const [ids, setIds] = useState<string[]>([]);

    useEffect(() => {
        if (ids.length) {
            const dataToSubmit: IDataToSubmit = {
                surveyId,
                questionId,
                selectedOptionId: ids,
            };

            changeQuestionStatus(questionId, true);

            submitAnswer(dataToSubmit);
        } else {
            changeQuestionStatus(questionId, false);
        }
    }, [ids.length]);

    const CheckboxClickHandler = (optionId: string) => {
        // if found
        if (ids.filter(item => item === optionId).length) {
            setIds(prev => {
                return prev.filter(item => item !== optionId);
            });
        } else {
            setIds(prev => [...prev, optionId]);
        }
    };

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={images.length === 1}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                                style={{ pointerEvents: 'none' }}
                            />
                        );
                    })}
                </Grid>
            )}

            <div style={ContainerStyles.buttonsContainer}>
                {options.map((item, index) => {
                    const { id, value, image } = item;
                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={index === options.length - 1 ? ContainerStyles.optionContainerLast : ContainerStyles.optionContainer}>
                                <Checkbox
                                    selected={ids.filter(opt => opt === id).length > 0}
                                    onClick={() => CheckboxClickHandler(id)}
                                >
                                    <SVG src={icons.Check} id='check' />
                                </Checkbox>
                                <Text css={ItemStyles.option}>{value}</Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(image && image.length > 0) && (
                                    image?.map(url => {
                                        return (
                                            <img
                                                src={url}
                                                alt='no img'
                                                style={{
                                                    height: '80px',
                                                    width: '80px',
                                                    marginRight: '10px',
                                                    marginTop: '25px',
                                                    marginLeft: '10px',
                                                    objectFit: 'contain',
                                                    marginBottom: '20px',
                                                    alignSelf: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    );
                })}

                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

export default connect(null, mapDispatchToProps)(SelectMultiple);

import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
    percentage: number;
}

const ProgressBar = ({
    percentage,
}: ProgressBarProps): JSX.Element => {
    return (
        <Container>
            <Progress percentage={percentage} />
        </Container>
    );
};

export default ProgressBar;

const Container = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    width: 100%;
    height: .5rem;
`;

const Progress = styled.div<ProgressBarProps>`
    width: ${props => props.percentage}%;
    height: 100%;
    background-color: #031b7b;
    position:relative;
`;

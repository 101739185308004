import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISurvey } from 'entities/surveys';
import { ChangeQuestionStatusActionPayload, SubmitAnswerActionPayload, SubmitAnswerFailureActionPayload, SurveyReduxState } from './types';

const initialState: SurveyReduxState = {
    actions: {
        getAllSurvey: false,
        submitAnswer: false,
    },
    surveys: null,
    surveyProgress: 0,
    surveyHasBasicInfo: false,
    error: {
        getAllSurvey: '',
        submitAnswer: '',
    },
};

const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        getAllSurveysAttempt: (state) => {
            state.actions.getAllSurvey = true;
            state.error.getAllSurvey = '';
        },
        getAllSurveysSuccess: (state, action: PayloadAction<ISurvey>) => {
            state.actions.getAllSurvey = false;
            state.error.getAllSurvey = '';

            if (action.payload) {
                state.surveys = action.payload;
            }
        },
        getAllSurveysFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAllSurvey = false;
            if (action.payload) {
                state.error.getAllSurvey = action.payload;
            }
        },

        setQuestionStatus: (state, action: ChangeQuestionStatusActionPayload) => {
            const { questionId, answered } = action.payload;

            if (state.surveys) {
                const index = state.surveys.questions.findIndex(item => item.id === questionId) || 0;

                state.surveys.questions[index].answered = answered;
            }
        },

        submitAnswerAttempt: (state, _action: SubmitAnswerActionPayload) => {
            state.actions.submitAnswer = true;
            state.error.submitAnswer = '';

            if (state.surveys) {
                const selectedQuestionIndex = state.surveys.questions.findIndex(item => item.id === _action.payload.questionId);

                state.surveys.questions[selectedQuestionIndex].loading = true;
            }
        },
        submitAnswerSuccess: (state, action: SubmitAnswerActionPayload) => {
            state.actions.submitAnswer = false;
            state.error.submitAnswer = '';

            if (state.surveys) {
                const selectedQuestionIndex = state.surveys.questions.findIndex(item => item.id === action.payload.questionId);

                state.surveys.questions[selectedQuestionIndex].loading = false;
            }
        },
        submitAnswerFailure: (state, action: SubmitAnswerFailureActionPayload) => {
            state.actions.submitAnswer = false;

            if (state.surveys) {
                const selectedQuestionIndex = state.surveys.questions.findIndex(item => item.id === action.payload.data.questionId);

                state.surveys.questions[selectedQuestionIndex].loading = false;

                state.error.submitAnswer = action.payload.error;
            }
        },
        surveySetProgress: (state, action: PayloadAction<number>) => {
            state.surveyProgress = action.payload;
        },
        surveySetSurveyHasBasicInfo: (state, action: PayloadAction<boolean>) => {
            state.surveyHasBasicInfo = action.payload;
        },
    },
});

export type SurveyState = typeof initialState;

export default {
    actions: surveySlice.actions,
    reducers: surveySlice.reducer,
};

import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import { ISetDOBParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

export default function* watchSetDOB(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSetDOBAttempt', handleSetDOB, api);
}

function* handleSetDOB(api: HomeGateway, data: PayloadAction<ISetDOBParams>) {
    const { dob } = data.payload;
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!dob) {
        yield put(Actions.homeSetDOBFailure('Please select date.'));
        return;
    }

    if (!scanId) {
        yield put(Actions.homeSetDOBFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.setDOB], { scanId, dob });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeSetDOBFailure(''));

        if (response.name) {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
        } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeSetDOBSuccess({ dob }));

        NavActions.navToPhoneNumber();
    }
}

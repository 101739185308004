import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import { LanguageSelectionEnum } from 'api/HomeBase';
import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

import Translate from 'translate/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGetScanInfo(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeGetScanInfoAttempt', handleGetScanInfo, api);
}

function* handleGetScanInfo(api: HomeGateway) {
    let scanId: string | null = '';

    if (!scanId) {
        scanId = yield* select(Selectors.homeGetScanId);
    }

    if (!scanId) {
        scanId = Utils.LocalStorage.getItem('scanId');
    }

    if (!scanId) {
        yield put(Actions.homeGetScanInfoFailure(''));
        yield put(Actions.errorSetGeneralErrorPageMessage('Sorry, we’re unable to verify your scan. Please try scanning your QR code again.'));

        NavActions.navToError();
        return;
    }

    if (scanId) {
        yield put(Actions.homeSetScanId(scanId));
    }

    const response = yield* call([api, api.getScanInfo], { scanId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeGetScanInfoFailure(''));

        if (response.name) {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
        } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            const { language, hasCompletedSurvey, qrType } = response.data;

            if (hasCompletedSurvey === true) {
                yield put(Actions.errorSetGeneralErrorPageMessage(Translate.t('errorPhoneUsedMessage1')));

                NavActions.navToError();
            }

            if (language) {
                Utils.User.setUserLanguage(LanguageSelectionEnum[language]);
                if (qrType !== undefined) {
                    yield put(Actions.homeGetLanguagePackAttempt({ qrType, lang: language }));
                }
            }
        }

        const currentScreen = window.location.href;

        if (currentScreen.match('/invalidPhone')) {
            if (response.data.stage === 501) {
                return;
            } if (response.data.stage < 104 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/unqualified')) {
            if (response.data.stage === 502) {
                return;
            } if (response.data.stage < 106 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/m/dob')) {
            if (response.data.stage < 102 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/m/phoneNumber')) {
            if (response.data.stage < 103 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/m/otp')) {
            if (response.data.stage < 104 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/m/basicInfo')) {
            if (response.data.stage < 105 || response.data.stage > 200) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/b/uniqueCodeAndPhoneNumber')) {
            if (response.data.stage < 202) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/b/otp' || 'b/v2/otp')) {
            if (response.data.stage < 203) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/b/basicInfoAndSurveyStatus')) {
            if (response.data.stage < 204) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        const isPathSame = (route: string) => {
            const currentPath = window.location.pathname;
            const checkIfMatch = currentPath.match(route);
            return !!(checkIfMatch && currentPath === route);
        };

        if (isPathSame('/survey')) {
            const stageIsInvalid = (response.data.stage < 200 && response.data.stage < 106) || (response.data.stage > 200 && response.data.stage < 205) || response.data.stage > 300;

            if (stageIsInvalid) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (isPathSame('/v2/survey')) {
            const stageIsInvalid = (response.data.stage < 200 && response.data.stage < 106) || (response.data.stage > 200 && response.data.stage < 204) || response.data.stage > 300;

            if (stageIsInvalid) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        if (currentScreen.match('/completedSurvey')) {
            if ((response.data.stage < 200 && response.data.stage < 107) || (response.data.stage > 200 && response.data.stage < 206) || response.data.stage > 300) {
                yield put(Actions.errorSetGeneralErrorPageMessage('Error 9995: Something went wrong. Please try again.'));
                NavActions.navToError();
            }
        }

        yield put(Actions.homeGetScanInfoSuccess(response.data));
    }
}

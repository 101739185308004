import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import SurveyGateway from 'api/Survey';

import Actions from 'redux/Actions';
import { GatewayResponseAwaited, GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import { SubmitAnswerActionPayload } from 'redux/slices/survey/types';
import NavActions from 'lib/NavActions';
import config from 'config';
import Utils from 'lib/Utils';

export default function* watchSubmitAnswer(api: SurveyGateway): SagaWatcherReturnType {
    yield takeEvery('survey/submitAnswerAttempt', handleSubmitAnswer, api);
}

function* handleSubmitAnswer(api: SurveyGateway, data: SubmitAnswerActionPayload) {
    const scanId = yield* select(Selectors.homeGetScanId);
    const { maxNumOfAnswerRetry } = config;

    if (!scanId) {
        yield put(Actions.errorSetGeneralErrorPageMessage('Something went wrong. Please try again'));
        NavActions.navToError();
        return;
    }

    let response: GatewayResponseAwaited<null>;
    response = yield* call([api, api.answerSurvey], { scanId, data: data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        for (let i = 0; i < maxNumOfAnswerRetry - 1; i += 1) {
            response = yield* call([api, api.answerSurvey], { scanId, data: data.payload });

            if (response.status === GatewayResponseStatus.Success) {
                break;
            }
        }
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.submitAnswerFailure({ error: response.message || 'Something went wrong please try again', data: data.payload }));

        if (response.name) {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
        } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.submitAnswerSuccess(data.payload));
    }
}

import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import { ISubmitBasicInfoAndSurveyStatusParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

export default function* watchSubmitBasicInfoAndSurveyStatus(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSubmitBasicInfoAndSurveyStatusAttempt', handleSubmitBasicInfoAndSurveyStatus, api);
}

function* handleSubmitBasicInfoAndSurveyStatus(api: HomeGateway, data: PayloadAction<ISubmitBasicInfoAndSurveyStatusParams>) {
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeSubmitBasicInfoAndSurveyStatusFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.submitBasicInfoAndSurveyStatus], { scanId, ...data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeSubmitBasicInfoAndSurveyStatusFailure(''));
        if (response.name === '3023') {
            NavActions.navToUnqualified();
        } else {
            if (response.name) {
                yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
            } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

            NavActions.navToError();
        }
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            // v1 - NavActions.navToSurvey();
            yield put(Actions.surveySetSurveyHasBasicInfo(true));
        }

        yield put(Actions.homeSubmitBasicInfoAndSurveyStatusSuccess(response.data));
    }
}

import Button from 'components/Button';
import Input from 'components/Input';
import Text from 'components/Text';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { ISubmitOTPParams } from 'redux/slices/home/types';
import { AppDispatch, RootState } from 'redux/store';
import Translate from 'translate/Translate';

import { Spinner } from 'reactstrap';
import { ContainerStyles, ItemStyles } from '../styles';

interface OTPProps {
    submittingOTP: boolean;
    submitOTPError: string;
    resendingOTP: boolean;
    phoneNumber: string;
    submitOTP: (params: ISubmitOTPParams) => void;
    resendOTP: () => void;
    resetSubmitOTP: () => void;
}

const OTP = (props: OTPProps): JSX.Element => {
    const {
        submittingOTP,
        submitOTPError,
        resendingOTP,
        phoneNumber,
        submitOTP,
        resendOTP,
        resetSubmitOTP,
    } = props;

    const [code, setCode] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        let intervalId: NodeJS.Timer;

        if (resendDisabled === true && timer > 0) {
            let interval = timer * 1;
            timeoutId = setTimeout(() => { setResendDisabled(false); }, timer * 1000);
            intervalId = setInterval(() => {
                setTimer(interval - 1);
                interval -= 1;
            }, 1000);
        }

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [resendDisabled]);

    const handleProceedClick = () => {
        if (code.length === 6) {
            submitOTP({ otp: code });
        }
    };

    const handleResendOTPClick = () => {
        resetSubmitOTP();
        setTimer(30);
        setResendDisabled(true);
        resendOTP();
    };

    return (
        <div style={{ ...ContainerStyles.mainContainer }}>
            <div style={{
                textAlign: 'start',
                flexWrap: 'wrap',
                color: 'black',
                width: '100%',
                marginTop: '40px',
            }}
            >
                <div style={{ fontSize: '28px', fontWeight: 'bold' }}>
                    {Translate.t('otpHeader')}
                </div>
                <div style={{ fontSize: '14px' }}>
                    {Translate.t('otpMessage1')}
                    <br />
                    {phoneNumber}
                </div>

                <div style={{ margin: '100px auto auto' }}>
                    <div style={{ fontSize: '14px', marginBottom: '10px', color: 'white' }}>
                        {Translate.t('otpCodeInputLabel')}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 2 }}>
                            <Input
                                type='tel'
                                disabled={submittingOTP}
                                value={code}
                                maxLength={6}
                                onChange={(e) => {
                                    resetSubmitOTP();
                                    if (!Number.isNaN(Number(e.currentTarget.value)) && !e.currentTarget.value.includes(' ')) {
                                        setCode(e.currentTarget.value);
                                    }
                                }}
                                style={{
                                    borderRadius: '0px',
                                    height: '40px',
                                    opacity: submittingOTP ? 0.5 : 1.0,
                                    cursor: submittingOTP ? 'not-allowed' : 'text',
                                }}
                            />
                        </div>
                        <Button
                            disabled={resendDisabled || submittingOTP}
                            onClick={handleResendOTPClick}
                            style={{
                                borderRadius: '0px',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: resendDisabled ? 'rgb(25, 155, 225, 0.5)' : 'rgb(25, 155, 225, 1.0)',
                                cursor: resendDisabled ? 'not-allowed' : 'pointer',
                                width: 'auto',
                            }}
                        >
                            {resendingOTP
                                ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Spinner size='sm' />
                                    </div>
                                )
                                : `${Translate.t('otpResendOtpButton')} ${resendDisabled ? `${timer}${Translate.t('otpResendOtpButtonSeconds')}` : ''}`}
                        </Button>
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: '60px' }}>
                <Button
                    disabled={submittingOTP || code.length !== 6}
                    onClick={() => handleProceedClick()}
                    style={{
                        ...ItemStyles.ProceedButton,
                        opacity: code && code.length === 6 ? 1 : 0.5,
                        cursor: code && code.length === 6 ? 'pointer' : 'not-allowed',
                        textAlign: 'center',
                    }}
                >
                    {submittingOTP ? <Spinner /> : Translate.t('otpNextButton')}
                </Button>

                <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{submitOTPError}</Text>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submittingOTP: Selectors.homeGetSubmitOTPAttempting(state),
    submitOTPError: Selectors.homeGetSubmitOTPError(state),
    resendingOTP: Selectors.homeGetResendOTPAttempting(state),
    phoneNumber: Selectors.homeGetPhoneNumber(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitOTP: (params: ISubmitOTPParams) => dispatch(Actions.homeSubmitOTPAttempt(params)),
    resendOTP: () => dispatch(Actions.homeResendOTPAttempt()),
    resetSubmitOTP: () => dispatch(Actions.homeResetSubmitOTP()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTP);

import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import Icons from 'assets/icons';
import { IQrTypeEnum, LanguageSelectionEnum } from 'api/HomeBase';

import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { ICommonCopies, ILanguagePack, ISubmitUniqueCodeAndPhoneNumberParams } from 'redux/slices/home/types';

import Translate from 'translate/Translate';

import { ContainerStyles, ItemStyles } from './styles';

interface UniqueCodeAndPhoneNumberProps {
    canSkipUniqueCode: boolean;
    submittingUniqueCodeAndPhoneNumber: boolean;
    submitUniqueCodeAndPhoneNumberError: string;
    languagePack: ILanguagePack;
    submitUniqueCodeAndPhoneNumber: (params: ISubmitUniqueCodeAndPhoneNumberParams) => void;
    resetUniqueCodeAndPhoneNumber: () => void;
    getCommonCopies: (qrType: IQrTypeEnum, qrId: string) => void;
    commonCopies: ICommonCopies | undefined;
    qrId: string;
    selectedLanguage: LanguageSelectionEnum;
    isOTPReady: boolean;
}

const UniqueCodeAndPhoneNumber = (props: UniqueCodeAndPhoneNumberProps): JSX.Element => {
    const {
        canSkipUniqueCode,
        submittingUniqueCodeAndPhoneNumber,
        submitUniqueCodeAndPhoneNumberError,
        languagePack,
        submitUniqueCodeAndPhoneNumber,
        resetUniqueCodeAndPhoneNumber,
        getCommonCopies,
        commonCopies,
        qrId,
        selectedLanguage,
        isOTPReady,
    } = props;

    const [inputPhoneNumber, setInputPhoneNumber] = useState<string>('60');
    const [uniqueCode, setUniqueCode] = useState('');

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);

        if (commonCopies === undefined && qrId) getCommonCopies(IQrTypeEnum.AnswerMultiple, qrId);
    }, []);

    // V2 - Reset unique code and phone number if OTP isn't ready
    useEffect(() => {
        if (!isOTPReady) {
            setUniqueCode('');
            setInputPhoneNumber('60');
        }
    }, [isOTPReady]);

    const renderUniqueCodeInput = () => {
        if (canSkipUniqueCode) return null;

        return (
            <RowInput>
                <RowInputText style={{ fontSize: '16px', color: 'white' }}>
                    {Translate.t('uniqueCodeHeader')}
                </RowInputText>
                <Input
                    disabled={submittingUniqueCodeAndPhoneNumber}
                    value={uniqueCode}
                    maxLength={6}
                    onChange={(e) => {
                        if (e.currentTarget.value.match(/^[a-z0-9]*$/i)) {
                            setUniqueCode(e.currentTarget.value.toLowerCase());
                        }
                    }}
                    style={{
                        opacity: submittingUniqueCodeAndPhoneNumber ? 0.5 : 1.0,
                        cursor: submittingUniqueCodeAndPhoneNumber ? 'not-allowed' : 'text',
                    }}
                    autoCapitalize='none'
                />
            </RowInput>
        );
    };

    const renderMedia = () => {
        if (!commonCopies?.stepOneMediaPath && !commonCopies?.stepTwoMediaPath) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img
                        height={150}
                        style={{ objectFit: 'contain', margin: '20px 10px 20px 40px', pointerEvents: 'none' }}
                        alt='brand'
                        src='/Step1.png'
                    />
                    <Indicator>
                        <img width='20px' alt='indicator' src={Icons.LeftArrow} />
                    </Indicator>
                </div>
            );
        }
        return (
            <>
                {commonCopies?.stepOneMediaPath && (
                    <RowContainer>
                        <img
                            style={{ objectFit: 'contain', pointerEvents: 'none', height: '200px' }}
                            alt='brand'
                            src={commonCopies?.stepOneMediaPath}
                        />
                    </RowContainer>
                )}
                {commonCopies?.stepTwoMediaPath && (
                    <RowContainer>
                        <img
                            style={{ objectFit: 'contain', pointerEvents: 'none', height: '200px' }}
                            alt='brand'
                            src={commonCopies?.stepTwoMediaPath}
                        />
                    </RowContainer>
                )}
            </>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={{
                textAlign: 'start',
                flexWrap: 'wrap',
                color: 'white',
                width: 'auto',
            }}
            >
                <div>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {Translate.t(canSkipUniqueCode ? 'phoneNumberMessage3' : 'phoneNumberMessage2')}
                    </div>

                    <RowInput>
                        <RowInputText>{Translate.t('phoneNumberMobileNoInputLabel')}</RowInputText>
                        <Input
                            type='tel'
                            disabled={submittingUniqueCodeAndPhoneNumber}
                            id='input1'
                            value={inputPhoneNumber || ''}
                            maxLength={12}
                            onChange={(e) => {
                                if (e.currentTarget.value.match(/^[0-9]*$/)) {
                                    resetUniqueCodeAndPhoneNumber();
                                    setInputPhoneNumber(e.currentTarget.value);
                                }
                            }}
                        />
                    </RowInput>

                    <div style={{ margin: '20px auto' }}>
                        {renderUniqueCodeInput()}
                    </div>

                    {!canSkipUniqueCode && (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '40px',
                                    fontSize: '15px',
                                }}
                            >
                                {Translate.t('uniqueCodeAndPhoneNumberHowToFindUniqueCode')}
                            </div>

                            <MediaContainer>
                                {renderMedia()}
                            </MediaContainer>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <Button
                    disabled={!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12}
                    onClick={() => {
                        if (canSkipUniqueCode) {
                            if (inputPhoneNumber.length >= 10 && inputPhoneNumber.length <= 12) {
                                submitUniqueCodeAndPhoneNumber({ code: '', phoneNumber: inputPhoneNumber });
                            }
                        } else if (inputPhoneNumber.length >= 10 && inputPhoneNumber.length <= 12) {
                            submitUniqueCodeAndPhoneNumber({ code: uniqueCode, phoneNumber: inputPhoneNumber });
                        }
                    }}
                    style={{
                        ...ItemStyles.ProceedButton,
                        opacity: (!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12) ? 0.5 : 1,
                        cursor: (!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12) ? 'not-allowed' : 'pointer',
                    }}
                >
                    {submittingUniqueCodeAndPhoneNumber ? <Spinner /> : Translate.t('OTPrequest')}
                </Button>

                {submitUniqueCodeAndPhoneNumberError && (
                    <Text style={{ ...ItemStyles.ErrorMessage, ...errorContainer }}>
                        {submitUniqueCodeAndPhoneNumberError}
                    </Text>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submittingUniqueCodeAndPhoneNumber: Selectors.homeGetSubmitUniqueCodeAndPhoneNumberAttempting(state),
    submitUniqueCodeAndPhoneNumberError: Selectors.homeGetSubmitUniqueCodeAndPhoneNumberError(state),
    languagePack: Selectors.homeGetLanguagePack(state),
    canSkipUniqueCode: Selectors.homeGetScanInfoCanSkipUniqueCode(state),
    commonCopies: Selectors.homeGetCommonCopies(state),
    qrId: Selectors.homeGetQrId(state),
    selectedLanguage: Selectors.homeGetSelectedLanguage(state),
    isOTPReady: Selectors.homeIsOTPScreenReady(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitUniqueCodeAndPhoneNumber: (params: ISubmitUniqueCodeAndPhoneNumberParams) => dispatch(Actions.homeSubmitUniqueCodeAndPhoneNumberAttempt(params)),
    resetUniqueCodeAndPhoneNumber: () => dispatch(Actions.homeResetSubmitUniqueCodeAndPhoneNumber()),
    getCommonCopies: (qrType: IQrTypeEnum, qrId: string) => dispatch(Actions.homeGetCommonCopiesAttempt({ qrType, qrId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniqueCodeAndPhoneNumber);

const RowInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-top: 20px;

    & > *:first-child {
        flex-basis: 40%;
    }
`;

const RowInputText = styled.div`
    font-size: 16px;
    color: white;
`;

const errorContainer = {
    alignItems: 'center',
    minHeight: '30px',
};

const slideLeft = keyframes`
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Indicator = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    animation: ${slideLeft} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const MediaContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
    gap: 10px;
    border: 1.5px solid black;
    background-color: #ffffffae;
`;

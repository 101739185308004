import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import Utils from 'lib/Utils';

import { IQrTypeEnum, LanguageSelectionEnum } from 'api/HomeBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { ICommonCopies, ISelectLanguageParams } from 'redux/slices/home/types';

import { Modal, ModalHeader, Spinner } from 'reactstrap';
import icons from 'assets/icons';
import Text from 'components/Text';
import Button from 'components/Button';

import { ContainerStyles, ItemStyles } from '../styles';

interface LanguageSelectionProps {
    selectingLanguage: boolean;
    selectLanguageError: string;
    commonCopies: ICommonCopies | undefined;
    selectLanguage: (params: ISelectLanguageParams) => void;
    resetSelectLanguage: () => void;
    getCommonCopies: (qrType: IQrTypeEnum) => void;
    getLanguagePack: (qrType: IQrTypeEnum, lang: LanguageSelectionEnum) => void;
}

const LanguageSelection = (props: LanguageSelectionProps): JSX.Element => {
    const {
        selectingLanguage,
        selectLanguageError,
        commonCopies,
        selectLanguage,
        resetSelectLanguage,
        getCommonCopies,
        getLanguagePack,
    } = props;

    const [langSelected, setLangSelected] = useState<LanguageSelectionEnum>();

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);

        Utils.User.setUserLanguage(LanguageSelectionEnum[2]);
        setLangSelected(2);
        if (commonCopies === undefined) getCommonCopies(IQrTypeEnum.AnswerOnce);
    }, []);

    const renderSelectLang = () => {
        if (commonCopies !== undefined) {
            if (langSelected === LanguageSelectionEnum.Malay) return commonCopies.homeSelectLangMs;
            if (langSelected === LanguageSelectionEnum.Chinese) return commonCopies.homeSelectLangZh;
            return commonCopies.homeSelectLangEn;
        } return '';
    };

    const renderDisclaimer = () => {
        if (commonCopies !== undefined) {
            if (langSelected === LanguageSelectionEnum.Malay) return commonCopies.disclaimerMs;
            if (langSelected === LanguageSelectionEnum.Chinese) return commonCopies.disclaimerZh;
            return commonCopies.disclaimerEn;
        } return '';
    };

    return (
        <div style={{ ...ContainerStyles.mainContainer, height: 'auto' }}>
            <div style={ContainerStyles.topContainer}>
                <div style={ContainerStyles.welcomeContainer}>
                    <Text>{commonCopies?.homeTitleEn}</Text>
                    <Text>{commonCopies?.homeTitleMs}</Text>
                    <Text>{commonCopies?.homeTitleZh}</Text>
                </div>

                <div style={ContainerStyles.pickContainer}>
                    <Text css={ItemStyles.pickYourLanguage}>
                        {renderSelectLang()}
                    </Text>
                </div>

                <div style={ContainerStyles.buttonsContainer}>
                    <Button
                        disabled={selectingLanguage}
                        onClick={() => {
                            setLangSelected(1);
                            if (selectLanguageError) resetSelectLanguage();
                        }}
                        css={langSelected === 1 ? ItemStyles.languageButtonSelected : ItemStyles.languageButton}
                        style={{
                            cursor: selectingLanguage ? 'not-allowed' : 'pointer',
                        }}
                    >
                        <Text>English</Text>
                        {langSelected === 1 && <SVG src={icons.Check} id='check' />}
                    </Button>

                    <Button
                        disabled={selectingLanguage}
                        onClick={() => {
                            setLangSelected(2);
                            if (selectLanguageError) resetSelectLanguage();
                        }}
                        css={langSelected === 2 ? ItemStyles.languageButtonSelected : ItemStyles.languageButton}
                        style={{
                            cursor: selectingLanguage ? 'not-allowed' : 'pointer',
                        }}
                    >
                        <Text>Malay</Text>
                        {langSelected === 2 && <SVG src={icons.Check} id='check' />}
                    </Button>

                    <Button
                        disabled={selectingLanguage}
                        onClick={() => {
                            setLangSelected(3);
                            if (selectLanguageError) resetSelectLanguage();
                        }}
                        css={langSelected === 3 ? ItemStyles.languageButtonSelected : ItemStyles.languageButton}
                        style={{
                            cursor: selectingLanguage ? 'not-allowed' : 'pointer',
                        }}
                    >
                        <Text>Chinese</Text>
                        {langSelected === 3 && <SVG src={icons.Check} id='check' />}
                    </Button>
                </div>
            </div>

            <div style={ContainerStyles.bottomContainer}>
                <Button
                    disabled={!langSelected || selectingLanguage}
                    onClick={() => {
                        if (langSelected) {
                            selectLanguage({ lang: langSelected });
                            getLanguagePack(IQrTypeEnum.AnswerOnce, langSelected);
                        }
                    }}
                    css={ItemStyles.submitButton}
                    style={{
                        opacity: langSelected ? 1 : 0.5,
                        cursor: langSelected ? 'pointer' : 'not-allowed',
                    }}
                >
                    {selectingLanguage ? <Spinner /> : 'SUBMIT'}
                </Button>
                <Text style={ItemStyles.ErrorMessage}>{selectLanguageError}</Text>

                <div style={ContainerStyles.disclaimerContainer}>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                        {renderDisclaimer()}
                    </Text>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectingLanguage: Selectors.homeGetSelectLanguageAttempting(state),
    selectLanguageError: Selectors.homeGetSelectLanguageError(state),
    commonCopies: Selectors.homeGetCommonCopies(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    selectLanguage: (params: ISelectLanguageParams) => dispatch(Actions.homeSelectLanguageAttempt(params)),
    resetSelectLanguage: () => dispatch(Actions.homeResetSelectLanguage()),
    getCommonCopies: (qrType: IQrTypeEnum) => dispatch(Actions.homeGetCommonCopiesAttempt({ qrType, qrId: '' })),
    getLanguagePack: (qrType: IQrTypeEnum, lang: LanguageSelectionEnum) => dispatch(Actions.homeGetLanguagePackAttempt({ qrType, lang })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);

const ResidingStateLOV = [
    'Johor',
    'Kedah',
    'Kelantan',
    'Malacca',
    'Negeri Sembilan',
    'Pahang',
    'Penang',
    'Perak',
    'Perlis',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu',
    'Kuala Lumpur',
];

const ProfessionLOV = [
    'Media And Comms',
    'Government Officer',
    'Healthcare',
    'Market Research',
    'Public Relations',
    'Tobacco',
    'Banking',
    'ECommerce',
    'Other',
];

const NicotineBrandLOV = [
    'Marlboro',
    'Chesterfield',
    'Bond Street',
    'Dunhill',
    'Rothmans',
    'Peter Stuyvesant',
    'Mevius',
    'Winston',
    'LD',
    'KYO',
];

const NicotineFlavorLOV = [
    {
        brand: 'Marlboro',
        flavour: [
            'Marlboro Red Box',
            'Marlboro Red Soft',
            'Marlboro Gold',
            'Marlboro Menthol',
            'Marlboro Black Menthol',
            'Marlboro Ice Blast',
            'Marlboro Double Burst',
            'Marlboro Tropical Splash',
            'Marlboro Summer Splash',
        ],
    },
    {
        brand: 'BondStreet',
        flavour: [
            'Bond Street Red',
            'Bond Street Blue',
            'Bond Street Green',
        ],
    },
    {
        brand: 'Chesterfield',
        flavour: [
            'Chesterfield Red',
            'Chesterfield Blue',
            'Chesterfield Charcoal Filter',
            'Chesterfield Menthol',
            'Chesterfield Purple',
        ],
    },
    {
        brand: 'Dunhill',
        flavour: [
            'Dunhill Classic Red',
            'Dunhill Red',
            'Dunhill Blue',
            'Dunhill Switch',
            'Dunhill Menthol',
            'Dunhill Menthol Boost',
            'Dunhill Mix',
            'Dunhill Botanical Burst',
        ],
    },
    {
        brand: 'Fiit',
        flavour: [
            'Fiit Regular',
            'Fiit Alpine',
            'Fiit Crisp',
        ],
    },
    {
        brand: 'Heets',
        flavour: [
            'Heets Amber',
            'Heets Bronze',
            'Heets Russet',
            'Heets Sienna',
            'Heets Black Green',
            'Heets Blue',
            'Heets Green Zing',
            'Heets Purple',
            'Heets Turquoise',
        ],
    },
    {
        brand: 'Kent',
        flavour: [
            'Kent Red Soft',
            'Kent Futura',
        ],
    },
    {
        brand: 'KYO',
        flavour: [
            'Kyo Original',
            'Kyo Silver',
            'Kyo Red',
        ],
    },
    {
        brand: 'LAndM',
        flavour: [
            'L&M Red',
            'L&M Charcoal',
            'L&M Menthol',
        ],
    },
    {
        brand: 'LD',
        flavour: [
            'LD Red',
            'LD Blue',
            'LD Green Flow',
            'LD Menthol',
            'LD Purple',
            'LD Red Flow',
            'LD Zoom',
            'LD Banana',
        ],
    },
    {
        brand: 'Mevius',
        flavour: [
            'Mevius Charcoal Filter',
            'Mevius Kiwami',
            'Mevius Sky Blue',
            'Mevius Wind Blue',
            'Mevius Elite',
            'Mevius Menthol Box',
            'Mevius Menthol Duo',
            'Mevius Menthol Soft',
            'Mevius Nova White',
            'Mevius LSS Yellow',
            'Mevius Quatro Mix',
        ],
    },
    {
        brand: 'PallMall',
        flavour: [
            'Pall Mall Red',
            'Pall Mall Blue',
            'Pall Mall Ice',
            'Pall Mall Menthol',
            'Pall Mall Mint',
        ],
    },
    {
        brand: 'PeterStuyvesant',
        flavour: [
            'Peter Stuyvesant Red',
            'Peter Stuyvesant Blue',
            'Peter Stuyvesant Menthol',
            'Peter Stuyvesant Remix',
        ],
    },
    {
        brand: 'Rothmans',
        flavour: [
            'Rothmans Red',
            'Rothmans Blue',
            'Rothmans Charcoal Filter',
            'Rothmans Menthol',
            'Rothmans Boost',
            'Rothmans Purple',
            'Rothmans Fuji Boost',
            'Rothmans Power Boost',
        ],
    },
    {
        brand: 'Sampoerna',
        flavour: [
            'Sampoerna A Red',
            'Sampoerna A Menthol',
        ],
    },
    {
        brand: 'Winston',
        flavour: [
            'Winston Red',
            'Winston Blue',
            'Winston Excel Duo',
        ],
    },
];

const SticksSmokedLOVS = [
    'Less than 1 sticks per week',
    'More than 1 sticks per week',
];

const CigarretteBrandsV2LOV = [
    {
        label: 'Marlboro',
        value: 11,
    },
    {
        label: 'Dunhill',
        value: 4,
    },
    {
        label: 'Mevius',
        value: 12,
    },
    {
        label: 'Chesterfield',
        value: 3,
    },
    {
        label: 'Rothmans',
        value: 15,
    },
    {
        label: 'LD',
        value: 10,
    },
    {
        label: 'Others',
        value: 18,
    },
];

export default {
    ResidingStateLOV,
    ProfessionLOV,
    NicotineBrandLOV,
    NicotineFlavorLOV,
    SticksSmokedLOVS,
    CigarretteBrandsV2LOV,
};

import React, { FunctionComponent, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

type OptionType = { [key: string]: any };
type OnMenuOpenCallback = () => void;
type OnMenuCloseCallback = () => void;

export interface DropdownProps {
    onChange: (e: any) => void;
    disabled?: boolean;
    options: OptionType[];
    placeholder: string;
    value?: any;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
    onChange,
    disabled,
    options,
    placeholder,
    value,
}: DropdownProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const handleMenuOpen:OnMenuOpenCallback = () => {
        setIsDropdownOpen(true);
    };

    const handleMenuClose:OnMenuCloseCallback = () => {
        setIsDropdownOpen(false);
    };

    const valueContainerStyles = {
        color: isDropdownOpen ? 'white' : 'black',
    };

    const customStyles: StylesConfig = {
        container: (provided, state) => ({
            ...provided,
            fontSize: '15px',
            opacity: state.isDisabled ? 0.5 : 1.0,
        }),
        control: (provided, state) => ({
            ...provided,
            padding: '5px',
            lineHeight: '17px',
            borderRadius: '0px',
            border: 0,
            backgroundColor: state.menuIsOpen ? '#021778' : '#ffffffa7',
            width: '100%',
            display: 'flex',
            boxShadow: 'none',
            borderBottom: '1px solid rgb(246,246,246, 0.5)',
            minHeight: '46px',
            '&:hover': {
                borderBottom: '1px solid rgb(246,246,246, 0.5)',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        input: (provided) => ({
            ...provided,
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            ...valueContainerStyles,
            ':hover': {
                color: '#a5aab5',
            },
        }),
        placeholder: (provided, state) => ({
            ...provided,
            ...valueContainerStyles,
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            // color: 'black',
            fontSize: '15px',
            borderRadius: '0px',
            marginTop: '0px',
            width: '100%',
        }),
        singleValue: (provided) => ({
            ...provided,
            ...valueContainerStyles,
        }),
        valueContainer: (provided) => ({
            ...provided,
            ...valueContainerStyles,
        }),
    };
    return (
        <Select
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            isSearchable={false}
            styles={customStyles}
            options={options}
            value={value}
            onChange={(e: any) => onChange(e)}
            isDisabled={disabled}
            placeholder={placeholder}
        />
    );
};

Dropdown.defaultProps = {
    disabled: false,
    value: '',
};

export default Dropdown;

import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import Text from 'components/Text';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import Translate from 'translate/Translate';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { ILanguagePack, ISetDOBParams } from 'redux/slices/home/types';
import Actions from 'redux/Actions';

import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import Datepicker from 'components/Datepicker';
import { ContainerStyles, ItemStyles } from '../styles';

interface DOBProps {
    settingDOB: boolean;
    setDOBError: string;
    languagePack: ILanguagePack;
    setDOB: (params: ISetDOBParams) => void;
    resetSetDOB: () => void;
}

const DOB = (props: DOBProps): JSX.Element => {
    const {
        settingDOB,
        setDOBError,
        languagePack,
        setDOB,
        resetSetDOB,
    } = props;

    const [termsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState(false);
    const [privacyNoticeModalIsOpen, setPrivacyNoticeModalIsOpen] = useState(false);
    const [dob, setDob] = useState<Date>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });

        return () => {
            resetSetDOB();
        };
    }, []);

    const handleProceedClick = () => {
        if (dob) {
            setDOB({ dob: dayjs(dob).format('YYYY-MM-DD') });
        }
    };

    const renderTermsAndConditionsModal = () => {
        return (
            <Modal
                size='xl'
                centered
                isOpen={termsAndConditionsModalIsOpen}
                toggle={() => setTermsAndConditionsModalIsOpen(!termsAndConditionsModalIsOpen)}
            >
                <ModalHeader>
                    <div style={{
                        color: '#199BE1',
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }}
                    >
                        {Translate.t('dobTermsAndConditionsModalHeader')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {languagePack?.terms}
                    </div>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => setTermsAndConditionsModalIsOpen(false)}
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                    >
                        {Translate.t('dobTermsAndConditionsModalOkButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const renderPrivacyNoticeModal = () => {
        return (
            <Modal
                size='xl'
                centered
                isOpen={privacyNoticeModalIsOpen}
                toggle={() => setPrivacyNoticeModalIsOpen(!privacyNoticeModalIsOpen)}
            >
                <ModalHeader>
                    <div style={{
                        color: '#199BE1',
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }}
                    >
                        {Translate.t('dobPrivacyNoticeModalHeader')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {languagePack?.privacyPolicy}
                    </div>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => setPrivacyNoticeModalIsOpen(false)}
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                    >
                        {Translate.t('dobPrivacyNoticeModalOkButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={{
                // display, justifyContent, flexDirection, and alignItems all for datepicker dropdown to position properly
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: '40px',
                flexWrap: 'wrap',
                color: 'black',
                width: '100%',
            }}
            >
                <div>
                    {Translate.t('dobMessage1')}
                    <Button
                        onClick={() => setTermsAndConditionsModalIsOpen(true)}
                        style={{ ...ItemStyles.DisclaimerButtons, margin: '0px 5px' }}
                    >
                        {Translate.t('dobMessageTermsAndConditions')}
                    </Button>
                    {Translate.t('dobMessageAnd')}
                    <Button
                        onClick={() => setPrivacyNoticeModalIsOpen(true)}
                        style={{ ...ItemStyles.DisclaimerButtons, margin: '0px 5px' }}
                    >
                        {Translate.t('dobMessagePrivacyNotice')}
                    </Button>
                    {Translate.t('dobMessageFirstParagraphFullStop')}

                    {Translate.t('dobMessage2')}
                </div>

                <div style={{
                    margin: '25px 0px',
                    fontSize: '16px',
                }}
                >
                    <Datepicker
                        dateFormat='yyyy'
                        selected={dob}
                        maxDate={dayjs().subtract(19, 'years').toDate()}
                        disabled={settingDOB}
                        onChange={(date: Date) => {
                            setDob(date);
                            resetSetDOB();
                        }}
                        placeholderText={Translate.t('dobDatepickerPlaceholder')}
                        showYearPicker
                    />

                </div>

                <div>
                    {Translate.t('dobMessage3')}
                </div>
            </div>

            <div style={{ marginBottom: '60px' }}>
                <Button
                    disabled={!dob}
                    onClick={() => handleProceedClick()}
                    style={{
                        ...ItemStyles.ProceedButton,
                        opacity: dob ? 1 : 0.5,
                        cursor: dob ? 'pointer' : 'not-allowed',
                    }}
                >
                    {settingDOB ? <Spinner /> : Translate.t('dobProceedButton')}
                </Button>

                <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{setDOBError}</Text>
            </div>
            {renderTermsAndConditionsModal()}
            {renderPrivacyNoticeModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    settingDOB: Selectors.homeGetSetDOBAttempting(state),
    setDOBError: Selectors.homeGetSetDOBError(state),
    languagePack: Selectors.homeGetLanguagePack(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDOB: (params: ISetDOBParams) => dispatch(Actions.homeSetDOBAttempt(params)),
    resetSetDOB: () => dispatch(Actions.homeResetSetDOB()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DOB);

import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';
import { Spinner } from 'reactstrap';
import Translate from 'translate/Translate';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { ISetPhoneNumberParams } from 'redux/slices/home/types';
import Actions from 'redux/Actions';

import { ContainerStyles, ItemStyles } from '../styles';

interface PhoneNumberProps {
    settingPhoneNumber: boolean;
    setPhoneNumberError: string;
    setPhoneNumber: (params: ISetPhoneNumberParams) => void;
    resetSetPhoneNumber: () => void;
}

const PhoneNumber = (props: PhoneNumberProps): JSX.Element => {
    const {
        settingPhoneNumber,
        setPhoneNumberError,
        setPhoneNumber,
        resetSetPhoneNumber,
    } = props;

    const [inputPhoneNumber, setInputPhoneNumber] = useState<string>('60');

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={{
                textAlign: 'start',
                marginTop: '40px',
                flexWrap: 'wrap',
                color: 'black',
                width: 'auto',
            }}
            >
                <div>
                    {Translate.t('phoneNumberMessage1')}
                    <br />
                    <br />
                    {Translate.t('phoneNumberMessage2')}
                    <br />
                    <br />
                    {Translate.t('phoneNumberMobileNoInputLabel')}

                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div>
                            <Input
                                type='tel'
                                disabled={settingPhoneNumber}
                                id='input1'
                                value={inputPhoneNumber || ''}
                                maxLength={12}
                                onChange={(e) => {
                                    if (e.currentTarget.value.match(/^[0-9]*$/)) {
                                        resetSetPhoneNumber();
                                        setInputPhoneNumber(e.currentTarget.value);
                                    }
                                }}
                                style={{
                                    width: 'auto',
                                    marginRight: '10px',
                                }}
                            />
                            <div style={{ fontSize: '14px', marginTop: '5px', opacity: '0.5' }}>
                                eg. 60123456789
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: '60px' }}>
                <Button
                    disabled={!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12}
                    onClick={() => {
                        if (inputPhoneNumber.length >= 10 && inputPhoneNumber.length <= 12) {
                            setPhoneNumber({ phoneNumber: inputPhoneNumber });
                        }
                    }}
                    style={{
                        ...ItemStyles.ProceedButton,
                        opacity: (!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12) ? 0.5 : 1,
                        cursor: (!inputPhoneNumber || inputPhoneNumber.length < 10 || inputPhoneNumber.length > 12) ? 'not-allowed' : 'pointer',
                    }}
                >
                    {settingPhoneNumber ? <Spinner /> : Translate.t('phoneNumberSubmitButton')}
                </Button>
                <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{setPhoneNumberError}</Text>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    settingPhoneNumber: Selectors.homeGetSetPhoneNumberAttempting(state),
    setPhoneNumberError: Selectors.homeGetSetPhoneNumberError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPhoneNumber: (params: ISetPhoneNumberParams) => dispatch(Actions.homeSetPhoneNumberAttempt(params)),
    resetSetPhoneNumber: () => dispatch(Actions.homeResetSetPhoneNumber()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);

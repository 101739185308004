import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ItemStyles } from 'containers/surveys/styles';

import { IQuestion } from 'entities/surveys';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Translate from 'translate/Translate';

import Button from './Button';
import Text from './Text';

interface SurveyFooterReduxProps {
    surveyProgress: number;
    setProgress: (param: number) => void;
    basicInfoAnswered: boolean;
}

interface SurveyFooterProps extends SurveyFooterReduxProps {
    questions: IQuestion[];
    onSubmit: () => void;
}

const SurveyFooter = ({
    questions,
    onSubmit,
    surveyProgress,
    setProgress,
    basicInfoAnswered,
}: SurveyFooterProps): JSX.Element | null => {
    const displayBackButton = false;

    const handleNextClick = () => {
        const currentQuestion = questions.find((_q, idx) => idx === surveyProgress);
        const isAnswered = currentQuestion?.answered;
        const questionId = currentQuestion?.id;

        if (isAnswered) {
            setProgress(surveyProgress + 1);
            toast.dismiss();
        } else {
            const toastErrorMessage = Translate.t('surveyQuestionNoAnswerToast');
            toast.error(toastErrorMessage, {
                toastId: `${questionId ?? 'question'}`,
            });
        }
    };

    const handleBackClick = () => {
        setProgress(surveyProgress - 1);
    };

    const renderFooterItems = () => {
        if (questions.length === (surveyProgress + 1)) {
            return (
                <>
                    {displayBackButton && (
                        <Button
                            onClick={handleBackClick}
                            css={ItemStyles.navButton}
                        >
                            <Text>
                                {Translate.t('surveyBackText')}
                            </Text>
                        </Button>
                    )}

                    <Button onClick={onSubmit} css={ItemStyles.submitButton}>
                        <Text>
                            {Translate.t('surveySubmitButton')}
                        </Text>
                    </Button>
                </>
            );
        }

        return (
            <>
                {displayBackButton && (
                    <Button
                        onClick={handleBackClick}
                        css={ItemStyles.navButton}
                    >
                        <Text>
                            {Translate.t('surveyBackText')}
                        </Text>
                    </Button>
                )}
                <Button
                    onClick={handleNextClick}
                    css={ItemStyles.navButton}
                >
                    <Text>
                        {Translate.t('surveyNextText')}
                    </Text>
                </Button>
            </>
        );
    };

    if (!basicInfoAnswered) return null;

    return (
        <RowContainer>
            {renderFooterItems()}
        </RowContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    surveyProgress: Selectors.surveyGetSurveyProgress(state),
    basicInfoAnswered: Selectors.surveyGetSurveyHasBasicInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setProgress: (param: number) => dispatch(Actions.surveySetProgress(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFooter);

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: .8rem;
    justify-content: flex-end;
`;

import OTPLOV from 'lib/LOVs/OTP';
import InactiveSurvey from 'lib/LOVs/InactiveSurvey';

export default {
    trackScanSurveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan Marlboro Vista.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan Marlboro yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the Marlboro Vista consumer feedback survey.

    This survey has ended, however do look out for future Marlboro consumer feedback surveys. Your reviews are important to us as we continue to improve.

    `,

    dobMessage1: '继续使用本网站，即表示你确认已经阅读并接受我们的',
    dobMessageTermsAndConditions: '条件与规则',
    dobMessageAnd: '和',
    dobMessagePrivacyNotice: '隐私声明',
    dobMessageFirstParagraphFullStop: '。',
    dobMessage2: '请在下面输入你的出生日期，以确认你已年满19岁。继续参与意味着你已经确认自己是吸烟者，并了解参与本次调查为自愿性质。',
    dobDatepickerPlaceholder: '年份',
    dobMessage3: '参与者将获得5令吉(RM5)回扣作为完成本次调查的感谢奖励。​',
    dobCancelButton: '取消',
    dobProceedButton: '继续',
    dobTermsAndConditionsModalHeader: 'Terms & Conditions',
    dobTermsAndConditionsModalOkButton: '继续',
    dobPrivacyNoticeModalHeader: 'Privacy Notice',
    dobPrivacyNoticeModalOkButton: '继续',

    uniqueCodeHeader: '独特代码',
    uniqueCodeMessage1: 'Please enter the unique code:',
    uniqueCodeInputLabel: '独特编码',
    uniqueCodeNextButton: 'NEXT',

    uniqueCodeErrorMessage1: '请输入唯一代码',
    uniqueCodeErrorMessage2: '无效的电话号码',

    uniqueCodeUsedMessage1: '感谢你有兴趣参与这项调查。 ',
    uniqueCodeUsedMessage2: '非常抱歉！这个独特编码已经使用过。',
    uniqueCodeUsedMessage3: '如果你想要参与这项调查，请输入新的独特编码。我们感谢你抽出时间参与。',

    phoneNumberMessage1: '你好，我们非常高兴看到你对我们的调查感兴趣。',
    phoneNumberMessage2: '请输入您的手机号码和调查独特代码，以便我们向您发送OTP。',
    phoneNumberMessage3: '请输入你的手机号码，以便我们发送验证码。',

    phoneNumberMobileNoInputLabel: '手机号码',
    phoneNumberSubmitButton: '提交',

    errorPhoneUsedMessage1: '非常抱歉！你之前已回答过此调查。',
    errorPhoneUsedMessage2: '​我们感谢你抽出时间参与。',

    otpHeader: 'OTP 验证码已被发送。',
    otpMessage1: '请输入发送到你手机的验证码，以验证你的号码。',
    otpCodeInputLabel: 'OTP',
    otpResendOtpButton: '重发 OTP',
    otpResendOtpButtonSeconds: '秒',
    otpNextButton: '提交',

    otpResendSuccess: 'OTP重新发送成功！',
    optResendFailure: ' 重新发送失败。请再试一次',
    otpResendFailure2: '请等待至少 30 秒，然后再重新发送 OTP',

    otpSubmitError: 'OTP无效。请索取一份新的。',

    basicInfoHeader: '让我们来了解你',
    basicInfoGenderInputLabel: '性',
    basicInfoGenderMale: '男',
    basicInfoGenderFemale: '女',
    basicInfoMobileNumberInputLabel: '手机号码',
    basicInfoDOBInputLabel: '出生日期',
    basicInfoResidingStateInputLabel: '居住地区',
    basicInfoResidingStateJohor: '柔佛',
    basicInfoResidingStateKedah: '吉打',
    basicInfoResidingStateKelantan: '吉兰丹',
    basicInfoResidingStateMalacca: '马六甲',
    basicInfoResidingStateNegeriSembilan: '森美兰',
    basicInfoResidingStatePahang: '彭亨',
    basicInfoResidingStatePerak: '霹雳',
    basicInfoResidingStatePerlis: '玻璃市',
    basicInfoResidingStatePenang: '槟城',
    basicInfoResidingStateSabah: '沙巴',
    basicInfoResidingStateSarawak: '砂拉越',
    basicInfoResidingStateSelangor: '雪兰莪',
    basicInfoResidingStateTerengganu: '丁加奴',
    basicInfoResidingStateKualaLumpur: '吉隆坡联邦直辖区',
    basicInfoResidingStateLabuan: '纳闽联邦直辖区',
    basicInfoResidingStatePutrajaya: '布城联邦直辖区',
    basicInfoEthnicityInputLabel: '种族',
    basicInfoEthnicityMalay: '马来人',
    basicInfoEthnicityChinese: '华人',
    basicInfoEthnicityIndian: '印度人',
    basicInfoEthnicityOthers: '其他',
    basicInfoProfessionInputLabel: '你是否在以下任何一个领域工作？',
    basicInfoProfessionAdvertisingJournalismPR: '广告，新闻，公共关系',
    basicInfoProfessionGovernmentOfficerHealthcareTobacco: '政府公务员，医疗保健，烟草业,',
    basicInfoProfessionMarketResearch: '市场调查',
    basicInfoProfessionOtherProfessions: '我不从事以下任何职业',
    basicInfoProfessionBlockedProfessions: '是',
    basicInfoProfessionNotBlockedProfessions: '否',
    basicInfoProfessionBankingOrFinance: '银行/金融',
    basicInfoProfessionManufacturing: '制造业',
    basicInfoProfessionTelecommunications: '电信',
    basicInfoProfessionDistributionOrLogistic: '分销/物流',
    basicInfoProfessionECommerceOrInformationTechnology: '电子商务/资讯科技',
    basicInfoProfessionMediaAndCommunicationsOrGovernmentOfficerOrHealthcare: '媒体与通讯/政府公务员/医疗保健',
    basicInfoProfessionMarketResearchOrPublicRelationsOrTobacco: '市场调查/公关/烟草业',
    basicInfoProfessionSalesOrMarketing: '销售/营销',
    basicInfoProfessionOthers: '以上皆不是',
    basicInfoProfessionHospitalityOrFoodAndBeverage: '接待/餐饮',
    basicInfoNicotineBrandInputLabel: '您最常抽的香烟牌子是什么？',
    basicInfoNicotineFlavorInputLabel: '你定期选择的烟草口味？​',
    basicInfoNicotineFlavorInputLabel2: ' 你定期选择的烟草口味？',
    basicInfoSticksSmokedInputLabel: '你一天平均吸多少支品牌生产的香烟?',
    basicInfoSticksSmokedLessThanOnePerWeek: '每星期少于1支',
    basicInfoSticksSmokedMoreThanOnePerWeek: '每星期超过1支',
    basicInfoCurrentlyBreastfeedingInputLabel: '你目前正在怀孕或哺乳吗？​',
    basicInfoCurrentlyBreastfeedingYes: '是',
    basicInfoCurrentlyBreastfeedingNo: '不是',
    basicInfoProceedButton: '提交',
    basicInfoErrorMessage: '请回答所有问题',

    unqualifiedMessage1: '非常抱歉！我们很遗憾通知你，你不符合这项调查的基准条件',
    unqualifiedMessage2: '​我们感谢你抽出时间参与。',

    answerMultipleStatusHeader: 'Thank you! Let\'s get started with the survey.',
    answerMultipleMessage1: 'Is this your first time buying Bond Street Cigarillo?',
    answerMultipleYesButton: 'YES',
    answerMultipleNoButton: 'NO',

    surveySelectDropdown: '选择 ...',
    surveyFillAllSurveyErrorMessage: '请回答所有问题',
    surveyShortFormAnswerErrorMessage: '请填写空白字段',
    surveySubmitButton: '下一步',

    rewardsCardTnGPin: 'TnG识别码',
    rewardsCardVoucherValueText: '优惠券值:',
    rewardsCardVoucherExpiryDateText: '优惠券有效期限:',
    rewardsCopyAnsweredNoMessage1: '点击',
    rewardsCopyAnsweredNoUrlLink: '此处',
    rewardsCopyAnsweredNoMessage2: '了解更好的吸烟替代方案',
    rewardsCopyAnsweredYesMessage1: '点击',
    rewardsCopyAnsweredYesUrlLink: '此处',
    rewardsCopyAnsweredYesMessage2: '了解更多关于IQOS和lil产品资讯。',

    rewardsNoRewardsLeftMessage1: '由于反应热烈，我们分配的 RM2 礼券已全部兑换完毕。',

    generalError: '抱歉！出了点差错。请重试。',
    rewardAlreadyAwardedError: '已给予奖励。',

    languageSelectionAndDOBHeaderContainerTitle: '年龄验证',
    languageSelectionAndDOBHeaderContainerSubTitle: '请在下方输入你的出生日期，以确认你已年满19岁。',
    languageSelectionAndDOBheaderContainerSubTitle2: '继续参与意味着你已经确认自己是吸烟者，并了解参与本次调查为自愿性质。',

    languageSelectionAndDOBHeaderContainerDatePick: '选择你的出生日期',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderMonth: '月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth1: '一月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth2: '二月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth3: '三月 ',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth4: '四月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth5: '五月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth6: '六月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth7: '七月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth8: '八月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth9: '九月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth10: '十月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth11: '十一月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth12: '十二月',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderYear: '年',
    languageSelectionAndDOBHeaderContainerMessage1: '本人特此声明并确认',
    languageSelectionAndDOBHeaderContainerMessage2: '本人在此进一步声明并确认本人目前不从事以下任何行业：',
    languageSelectionAndDOBHeaderContainerContent1: '我已年满 19 岁',
    languageSelectionAndDOBHeaderContainerContent2: '我目前没有怀孕，也没有哺乳',
    languageSelectionAndDOBHeaderContainerContent3: '我已阅读并同意',
    languageSelectionAndDOBHeaderContainerContent4: '我已阅读并同意',
    languageSelectionAndDOBHeaderContainerContent5: '过去 2 个月我每周吸超过 1 支烟',
    languageSelectionAndDOBHeaderContainerContent6: '过去 2 个月我每周吸超过 1 支烟',
    languageSelectionAndDOBHeaderContainerContent7: '广告、新闻、公共关系、市场研究',
    languageSelectionAndDOBHeaderContainerContent8: '政府官员、法律、医疗保健、烟草',
    languageSelectionAndDOBHeaderContainerContent9: '市场调查',
    languageSelectionAndDOBHeaderContainerTermsAndConditions: '条款和条件',
    languageSelectionAndDOBHeaderContainerPrivacyNotice: '隐私声明',
    languageSelectionAndDOBHeaderContainerAnd: ' 和',
    languageSelectionAndDOBHeaderContainerDisclaimer: '免责声明',
    languageSelectionAndDOBHeaderContainerFullStop: '。',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholder: '年份',
    languageSelectionAndDOBSubmitButton: '提交',
    languageSelectionAndDOBTermsAndConditionsModalHeader: '条件与规则',
    languageSelectionAndDOBTermsAndConditionsModalOkButton: '提交',
    languageSelectionAndDOBPrivacyNoticeModalHeader: '隐私声明',
    languageSelectionAndDOBPrivacyNoticeModalOkButton: '提交',
    languageSelectionAndDOBDisclaimerModalHeader: '免责声明',
    languageSelectionAndDOBDisclaimerModalOkButton: '提交',

    skipRewardTermsAndConditionTitle: '合格标准',
    skipRewardTermsAndConditionContent1: '此调查仅开放给符合以下条件的合法成年吸烟者：',
    skipRewardTermsAndConditionContent2: '年满 19 岁或以上。',
    skipRewardTermsAndConditionContent3: '目前没有怀孕也没有哺乳。',
    skipRewardTermsAndConditionContent4: 'T在过去的 2 个月里，我每周抽超过 1 支的烟。',
    skipRewardTermsAndConditionContent5: '此调查将进行至存货售完即止。',

    skipRewardDisclaimerContent1: '此调查无意以任何方式促进吸烟和/或使用香烟产品/品牌或一般其他烟草制品。请勿在你的任何社交媒体或资讯传递平台讨论这项调查。',
    skipRewardDisclaimerContent2: '吸烟既危险又容易上瘾。目前不存在安全的烟草，也不能假设任何香烟品牌的类型或产品形式比任何其他品牌更安全。此调查的目的并不是为了解决吸烟和健康相关问题，也并非为了解决开始吸烟、戒烟或尝试戒烟的相关问题。',

    skipRewardPrivacyNoticeTitle1: '简介',
    skipRewardPrivacyNoticeTitle2: '信息收集',
    skipRewardPrivacyNoticeTitle3: '信息使用',
    skipRewardPrivacyNoticeTitle4: '向第三方披露信息',
    skipRewardPrivacyNoticeTitle5: '信息保留',
    skipRewardPrivacyNoticeTitle6: '你的个人信息权利',
    skipRewardPrivacyNoticeTitle7: '信息安全',
    skipRewardPrivacyNoticeTitle8: '我们隐私声明的变更',
    skipRewardPrivacyNoticeTitle9: '联系信息',

    skipRewardPrivacyNoticeContent1: '本隐私声明说明了我们(Bike Bear Sdn Bhd)如何收集、使用和共享与你完成任何调查、焦点小组、访谈、研究的相关信息，或其他由Bike Bear Sdn Bhd 主导并与本隐私声明有所链接或引用其内容的相关信息收集活动(统称为“调查”)。通过回答其中一项调查，即表示你同意根据本隐私声明使用此类信息。',
    skipRewardPrivacyNoticeContent2: '我们可能会收集你在完成调查时提供的个人信息，例如：',
    skipRewardPrivacyNoticeContent2subContent1: '居住地',
    skipRewardPrivacyNoticeContent2subContent2: '种族',
    skipRewardPrivacyNoticeContent2subContent3: '电话号码',
    skipRewardPrivacyNoticeContent2subContent4: '出生日期',
    skipRewardPrivacyNoticeContent2subContent5: '关于你个人且与调查相关的任何其他信息',
    skipRewardPrivacyNoticeContent3: '我们将在调查中收集的你的个人信息用于相关调查所阐述的目的，或者在我们对此类收集和使用拥有合法商业利益或其他法律依据的情况下。 你向我们提交的个人信息，可能会用于在你访问调查时验证你的身份、管理我们与你的关系（包括你的任何请求），或定制或改进我们的调查。',
    skipRewardPrivacyNoticeContent4: '我们也可能与你联系，寻求对我们或我们的附属机构出于研究目的提供的服务反馈。你的个人信息也可能用于保护我们，以及我们用户的权利或财产。',
    skipRewardPrivacyNoticeContent5: '我们可能会将你的个人信息提供给我们的关联公司作为调查用途，包括对通过调查收集的数据进行分析。你的个人信息也可能会披露给其他第三方，即我们的客户Philip Morris (Malaysia) Sdn Bhd，以协助他们做出产品和服务相关的决策。请注意，我们有必要基于此目的处理你的个人数据，否则你将无法参加本次调查。',
    skipRewardPrivacyNoticeContent6: '个人信息也可能会披露给执法、监管或其他政府机构，或其他第三方，每种情况都是为了遵守法律、监管或国家安全义务或相关要求。',
    skipRewardPrivacyNoticeContent7: '在向其他与我们公司服务无关联性的独立第三方共享你的个人信息之前，我们将发出通知并征求你的同意，除非本隐私声明或该项调查已经另行允许此类信息共享。',
    skipRewardPrivacyNoticeContent8: '所有这些披露都可能涉及将个人信息传输到不具备与你目前居住地相似的有效数据保护规则之国家或地区。通过回答调查，即表示你同意上述的信息披露。',
    skipRewardPrivacyNoticeContent9: '我们在上述“信息收集”章节引用的任何个人信息，可能会基于此处规定的目的披露给本章节中指定的第三方。',
    skipRewardPrivacyNoticeContent10: '在本隐私声明中概述的目的期间，我们会保留合理必要的个人信息。无论该目的持续多久，我们可能会在必要时保留你的信息，以遵守我们的法律或专业义务、执行我们的协议或解决争议。',
    skipRewardPrivacyNoticeContent11: '你可以选择如何收集、使用和共享你的个人信息。',
    skipRewardPrivacyNoticeContent12: '你可能有权要求我们确认我们是否处理了你的个人信息，或者我们是否已经更正、删除或停止处理你的个人信息。你可能也有权要求我们提供以下与我们持有你个人信息相关的信息：',
    skipRewardPrivacyNoticeContent12SubContent1: '我们收集的类别和/或特定的个人信息',
    skipRewardPrivacyNoticeContent12SubContent2: '收集个人信息的来源类别',
    skipRewardPrivacyNoticeContent12SubContent3: '收集个人信息的商业或商业目的',
    skipRewardPrivacyNoticeContent12SubContent4: '我们与之共享个人信息的第三方类别',
    skipRewardPrivacyNoticeContent13: '若你想要根据上述有关你个人信息的适用法律行使你的任何权利，请致电 1-800-88-0033 联系我们。联系我们时请提供你的完整姓名、手机号码，以及你提出的具体要求类别。',
    skipRewardPrivacyNoticeContent14: '我们制定了合理的技术和操作安全商业标准，以保护用户在调查中提供的所有个人信息，免遭未经授权的访问、披露、更改或破坏。',
    skipRewardPrivacyNoticeContent15: '我们可能会自行决定不时修改或修订本隐私声明。当我们更改本隐私声明时，我们将修改本页顶部的修订日期，并且此类修改或修订的隐私声明自该修订日期起对你和你的信息有效。我们鼓励你定期查看本隐私声明以了解任何更新内容。',
    skipRewardPrivacyNoticeContent16: '如果你对与调查相关的个人隐私有任何疑问或疑虑，请致电 1-800-88-0033 联系我们。',

    uniqueCodeAndPhoneNumberMessage1: '你好！我们很高兴看到你有兴趣参与我们的调查。请填写以下信息，我们将向你发送验证码。',
    uniqueCodeAndPhoneNumberMessage2: '请输入您的手机号码和调查独特代码，以便我们向您发送OTP。',
    uniqueCodeAndPhoneNumberHowToFindUniqueCode: '在袖子内寻找您的独特代码。',

    uniqueCodeAndPhoneNumberStep1: '步骤1 ',
    uniqueCodeAndPhoneNumberStep1Message: '撕开包装旁侧的粘贴标签。',

    uniqueCodeAndPhoneNumberStep2: '步骤2',
    uniqueCodeAndPhoneNumberStep2Message: '查找二维码贴纸后方的唯一代码。',

    uniqueCodeAndPhoneNumberError1: '请输入唯一代码！',
    uniqueCodeAndPhoneNumberError2: '无效的电话号码！',

    uniqueCodeAndPhoneNumberErrorBlocked: `嗨！您已经达到我们的回复限制了。

    您的热情是我们的荣幸！继续关注我们未来的调查。您的反馈帮助我们为您提供更好的服务。

    谢谢！
    `,

    bondOtpMessage1: `请输入发给 +${OTPLOV.phoneNumber} 的OTP`,

    bondBasicInfoFirstTimeBuyerInputLabel: '这是你第一次购买Bond Street Cigarillo雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelSS: '这是你第一次购买Marlboro Summer Splash雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelTS: '这是你第一次购买Marlboro Tropical Splash雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelGeneric: '请问这是您第一次购买',

    bondBasicInfoFirstTimeBuyerYes: '是',
    bondBasicInfoFirstTimeBuyerNo: '否',

    selectLanguagePlaceholder: '选择语言',

    invalidUniqueCodeError: '此独特代码已经被使用过。如果您想继续，请输入新的独特代码。',

    languageSelectionAndDOBHeaderContainerAgeRange: '年龄',

    surveyInactiveErrorMessage: `
    我们的调查已经结束。

    感谢所有参与调查的人。您的反馈不但重要，而且能帮助我们为您提供更好的服务。

    敬请期待我们的下一次调查。希望能再次收到您的反馈。

    谢谢！
    `,

    surveyNextText: '下一题',
    surveyBackText: '后退',
    surveyQuestionNoAnswerToast: '请回答这个问题',

    languageSelectionAndDOBAgeError: '抱歉，您必须年满19岁才能参与此项调查',

    languageSelectionAndDOBAgreementText: '我已阅读并同意{terms}、{disclaimer}和{privacyNotice}',

    OTPrequest: '要求OTP',

    goBackPromptMessage: '您确定要返回？你将会失去您的进度。',
    goBackToOtpPromptMessage: '您稍后必须再次输入您的电话号码才能继续。',

    otherBrandsLabel: '其他的',
    surveyTitle: '调查问题',

    inactivityModalTitle: 'Attention',
    inactivityModalQuestion: 'Are you still using this page?',
    inactivityModalYesButton: 'Yes',

    rewardPS: '温馨提示- 关闭后您将无法再次打开此页。',

};

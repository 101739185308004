import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Translate from 'translate/Translate';
import { ContainerStyles, ItemStyles } from 'containers/home/styles';
import Text from 'components/Text';

const UniqueCodeUsed = (): JSX.Element => {
    ReactGA.gtag('event', 'page_view', {
        page_title: window.location.pathname,
        page_location: window.location.pathname,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={ContainerStyles.errorContainer}>
            <Text style={{ whiteSpace: 'pre-line' }} css={ItemStyles.errorText}>
                {Translate.t('uniqueCodeUsedMessage1')}
                <br />
                <br />
                {Translate.t('uniqueCodeUsedMessage2')}
                <br />
                <br />
                {Translate.t('uniqueCodeUsedMessage3')}
            </Text>
        </div>
    );
};

export default UniqueCodeUsed;

import { push, replace } from 'redux-first-history';

import { store } from 'redux/store';

export interface INavActionState {
    [key: string]: any;
}

// NOTES:
// If you want to add new routes and is dependent on the stage survey,
// don't forget to modify getScanInfo saga and adjust accordingly

const navPush = (routeName: string, state?: INavActionState): void => {
    store.dispatch(push(routeName, state));
};

const navReplace = (routeName: string): void => {
    store.dispatch(replace(routeName));
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/');

const navToLanguageSelection = (qrId: string): void => navPush(`/m/q/${qrId}`);

const navToDOB = (): void => navPush('/m/dob');

const navToPhoneNumber = (): void => navPush('/m/phoneNumber');

const navToOTP = (): void => navPush('/m/otp');

const navToBasicInfo = (): void => navPush('/m/basicInfo');

const navToInvalidPhone = (): void => navPush('/invalidPhone');

const navToUnqualified = (): void => navPush('/unqualified');

const navToRewards = (): void => navPush('/completedSurvey');

const navToError = (): void => navPush('/error');

const navToSurvey = (): void => navPush('/survey');

const navToLanguageSelectionAndDOB = (qrId: string): void => navPush(`/b/q/${qrId}`);

const navToUniqueCodeAndPhoneNumber = (): void => navPush('/b/uniqueCodeAndPhoneNumber');

const navToBondOtp = (): void => navPush('/b/otp');

const navToUniqueCodeUsed = (): void => navPush('/uniqueCodeUsed');

const navToBasicInfoAndSurveyStatus = (): void => navPush('/b/basicInfoAndSurveyStatus');

const navToOTPScreenV2 = (): void => navPush('/b/v2/otp');

const navToSurveyV2 = (): void => navPush('/v2/survey');

export default {
    navResetToLogin,

    navToHome,

    navToLanguageSelection,

    navToDOB,

    navToPhoneNumber,

    navToOTP,

    navToBasicInfo,

    navToInvalidPhone,

    navToUnqualified,

    navToRewards,

    navToError,

    navToSurvey,

    navToLanguageSelectionAndDOB,

    navToUniqueCodeAndPhoneNumber,

    navToBondOtp,

    navToUniqueCodeUsed,

    navToBasicInfoAndSurveyStatus,

    navToOTPScreenV2,

    navToSurveyV2,

    navReplace,
};

import React from 'react';
import ReactDatepicker from 'react-datepicker';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import icons from 'assets/icons';
import Button from './Button';
import './styles/DatepickerStyles.css';

export interface DatepickerProps {
    onChange: (date: Date) => void;
    dateFormat?: any;
    selected?: Date | null | undefined;
    maxDate?: Date | null | undefined;
    minDate?: Date | null | undefined;
    disabled?: boolean | undefined;
    placeholderText?: string | undefined;
    showMonthPicker?:boolean;
    showYearPicker?: boolean;
}

const StyledDatepicker = styled(ReactDatepicker) <DatepickerProps>`
    width: 40%;
`;

const CustomInput = (props: React.HTMLProps<HTMLButtonElement>) => {
    const { onClick, value, placeholder } = props;

    if (!onClick) return <div />;

    return (
        <Button
            onClick={onClick}
            style={{
                borderRadius: '0',
                width: '100px',
                backgroundColor: 'F6F6F6',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '16px',
                filter: 'drop-shadow(0px 0px 10px #1313135e)',
            }}
            {...props}
        >
            {value || placeholder}
            <div style={{ position: 'absolute', right: '0', top: '7px' }}>
                <SVG src={icons.ChevronDown} />
            </div>
        </Button>
    );
};

const Datepicker = (props: DatepickerProps): JSX.Element => {
    const {
        onChange,
        dateFormat = 'dd/MM/yyyy',
        selected,
        maxDate,
        minDate,
        disabled,
        placeholderText,
        showMonthPicker,
        showYearPicker,
    } = props;

    return (
        <StyledDatepicker
            dateFormat={dateFormat}
            showYearDropdown
            showMonthDropdown
            showMonthYearPicker={showMonthPicker}
            showFullMonthYearPicker={showMonthPicker}
            // dropdownMode='select'
            selected={selected}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            onChange={onChange}
            placeholderText={placeholderText}
            showYearPicker={showYearPicker}
            popperPlacement='bottom'
            popperModifiers={[
                {
                    name: 'preventOverflow',
                    options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                        mainAxis: true,
                        padding: 20,
                    },
                },
            ]}
            customInput={<CustomInput placeholder={placeholderText} />}
            wrapperClassName='datepicker'
        />
    );
};

Datepicker.defaultProps = {
    dateFormat: 'dd/MM/yyyy',
    selected: undefined,
    maxDate: undefined,
    minDate: undefined,
    disabled: undefined,
    placeholderText: undefined,
};

export default Datepicker;

import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import HomeScreen from 'containers/home';

import LanguageSelectionScreen from 'containers/home/marlboro/LanguageSelection';
import DOBScreen from 'containers/home/marlboro/DOB';
import PhoneNumberScreen from 'containers/home/marlboro/PhoneNumber';
import SurveyScreen from 'containers/surveys/surveys';
import BasicInfoScreen from 'containers/home/marlboro/BasicInfo';
import OTPScreen from 'containers/home/marlboro/OTP';
import InvalidPhoneNumberScreen from 'containers/home/ErrorPhoneUsed';
import UnqualifiedScreen from 'containers/home/Unqualified';

import LanguageSelectionAndDOBScreen from 'containers/home/bond/LanguageSelectionAndDOB';
import UniqueCodeAndPhoneNumberScreen from 'containers/home/bond/UniqueCodeAndPhoneNumber';
import BondOTPScreen from 'containers/home/bond/BondOTP';
import BasicInfoAndSurveyStatusScreen from 'containers/home/bond/BasicInfoAndSurveyStatus';

import RewardsScreen from 'containers/rewards';

import ErrorScreen from 'containers/Error';

import UniqueCodeUsed from 'containers/home/UniqueCodeUsed';

import OTPScreenV2 from 'containers/home/v2/OTPScreenV2';

import SurveyScreenV2 from 'containers/v2/surveys';

import PrivateRoute from './PrivateRoutes';

// NOTES:
// If you want to add new routes and is dependent on the stage survey,
// don't forget to modify getScanInfo saga and adjust accordingly

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route path='/m/dob' element={<DOBScreen />} />

            <Route path='/m/phoneNumber' element={<PhoneNumberScreen />} />

            <Route path='/m/basicInfo' element={<BasicInfoScreen />} />

            <Route path='/m/otp' element={<OTPScreen />} />

            <Route path='/survey' element={<SurveyScreen />} />

            <Route path='/v2/survey' element={<SurveyScreenV2 />} />

            <Route path='/completedSurvey' element={<RewardsScreen />} />
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/q/:qrId' element={<HomeScreen />} />

                <Route path='/m/q/:qrId' element={<LanguageSelectionScreen />} />

                <Route path='/b/q/:qrId' element={<LanguageSelectionAndDOBScreen />} />

                <Route path='/b/uniqueCodeAndPhoneNumber' element={<UniqueCodeAndPhoneNumberScreen />} />

                <Route path='/b/otp' element={<BondOTPScreen />} />

                <Route path='/b/v2/otp' element={<OTPScreenV2 />} />

                <Route path='/b/basicInfoAndSurveyStatus' element={<BasicInfoAndSurveyStatusScreen />} />

                <Route path='/error' element={<ErrorScreen />} />

                <Route path='/invalidPhone' element={<InvalidPhoneNumberScreen />} />

                <Route path='/unqualified' element={<UnqualifiedScreen />} />

                <Route path='/uniqueCodeUsed' element={<UniqueCodeUsed />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {/* nav to general error screen here */}
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;

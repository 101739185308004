import OTPLOV from 'lib/LOVs/OTP';
import InactiveSurvey from 'lib/LOVs/InactiveSurvey';

export default {
    trackScanSurveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan Marlboro Vista.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan Marlboro yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the Marlboro Vista consumer feedback survey.

    This survey has ended, however do look out for future Marlboro consumer feedback surveys. Your reviews are important to us as we continue to improve.

    `,

    dobMessage1: 'Dengan menggunakan laman web ini, anda mengesahkan bahawa anda telah membaca dan menerima',
    dobMessageTermsAndConditions: 'Terma Penggunaan',
    dobMessageAnd: 'dan',
    dobMessagePrivacyNotice: 'Notis Privasi',
    dobMessageFirstParagraphFullStop: 'kami. ',
    dobMessage2: 'Sila masukkan tarikh lahir anda di bawah untuk mengesahkan bahawa anda berumur 19 tahun atau ke atas. Dengan meneruskan tinjauan ini, anda juga mengesahkan bahawa anda adalah perokok dan memahami bahawa penyertaan anda dalam tinjauan ini adalah secara sukarela.',
    dobDatepickerPlaceholder: 'YYYY',
    dobMessage3: 'Peserta akan menerima RM5 sebagai tanda penghargaan kerana melengkapkan tinjauan ini.​',
    dobCancelButton: 'BATAL',
    dobProceedButton: 'TERUSKAN',
    dobTermsAndConditionsModalHeader: 'Terms & Conditions',
    dobTermsAndConditionsModalOkButton: 'TERUSKAN',
    dobPrivacyNoticeModalHeader: 'Privacy Notice',
    dobPrivacyNoticeModalOkButton: 'TERUSKAN',

    uniqueCodeHeader: 'Kod Unik',
    uniqueCodeMessage1: 'Please enter the unique code:',
    uniqueCodeInputLabel: 'Kod Unik',
    uniqueCodeNextButton: 'NEXT',

    uniqueCodeErrorMessage1: 'Sila masukkan kod unik',
    uniqueCodeErrorMessage2: 'Nombor telefon tidak sah',

    uniqueCodeUsedMessage1: 'Terima kasih terhadap minat anda untuk turut serta dalam tinjauan ini.',
    uniqueCodeUsedMessage2: 'Namun, kod unik ini telah digunakan.',
    uniqueCodeUsedMessage3: 'Sila masukkan kod unik baharu sekiranya anda ingin turut serta dalam tinjauan ini.',

    phoneNumberMessage1: 'Hai, kami gembira melihat anda berminat dalam tinjauan kami.',
    phoneNumberMessage2: 'Sila masukkan nombor telefon anda dan kod unik tinjauan untuk menerima OTP daripada kami.',
    phoneNumberMessage3: 'Sila masukkan nombor telefon kepada kami untuk pengesahan kod.',

    phoneNumberMobileNoInputLabel: 'Nombor Telefon',
    phoneNumberSubmitButton: 'Hantar',

    errorPhoneUsedMessage1: 'Maaf, anda telah menjawab tinjauan ini.',
    errorPhoneUsedMessage2: 'Terima kasih untuk masa anda.',

    otpHeader: 'OTP telah dihantar',
    otpMessage1: 'Sila sahkan nombor anda dengan memasukkan kod pengesahan yang telah dihantar kepada nombor telefon anda',
    otpCodeInputLabel: 'OTP',
    otpResendOtpButton: 'Hantar semula OTP',
    otpResendOtpButtonSeconds: 's',
    otpNextButton: 'Hantar',

    otpResendSuccess: 'OTP berjaya dihantar semula!',
    optResendFailure: 'Hantar semula gagal. Sila cuba lagi.',
    otpResendFailure2: 'Sila tunggu sekurang-kurangnya 30 saat sebelum menghantar semula OTP',

    otpSubmitError: 'OTP tidak sah. Sila minta yang baharu.',

    basicInfoHeader: 'Mari mengenali anda',
    basicInfoGenderInputLabel: 'Jantina',
    basicInfoGenderMale: 'Lelaki',
    basicInfoGenderFemale: 'Perempuan',
    basicInfoMobileNumberInputLabel: 'Nombor Telefon',
    basicInfoDOBInputLabel: 'Tarikh Lahir',
    basicInfoResidingStateInputLabel: 'Negeri Tempat Tinggal',
    basicInfoResidingStateJohor: 'Johor',
    basicInfoResidingStateKedah: 'Kedah',
    basicInfoResidingStateKelantan: 'Kelantan',
    basicInfoResidingStateMalacca: 'Melaka',
    basicInfoResidingStateNegeriSembilan: 'Negeri Sembilan',
    basicInfoResidingStatePahang: 'Pahang',
    basicInfoResidingStatePerak: 'Perak',
    basicInfoResidingStatePerlis: 'Perlis',
    basicInfoResidingStatePenang: 'Pulau Pinang',
    basicInfoResidingStateSabah: 'Sabah',
    basicInfoResidingStateSarawak: 'Sarawak',
    basicInfoResidingStateSelangor: 'Selangor',
    basicInfoResidingStateTerengganu: 'Terengganu',
    basicInfoResidingStateKualaLumpur: 'WP Kuala Lumpur',
    basicInfoResidingStateLabuan: 'WP Labuan',
    basicInfoResidingStatePutrajaya: 'WP Putrajaya',
    basicInfoEthnicityInputLabel: 'Etnik',
    basicInfoEthnicityMalay: 'Melayu',
    basicInfoEthnicityChinese: 'Cina',
    basicInfoEthnicityIndian: 'India',
    basicInfoEthnicityOthers: 'Lain-lain',
    basicInfoProfessionInputLabel: 'Adakah anda berkerja dalam industri yang berikut?',
    basicInfoProfessionAdvertisingJournalismPR: 'Pengiklanan, Kewartawanan, Perhubungan Awam',
    basicInfoProfessionGovernmentOfficerHealthcareTobacco: 'Pegawai Kerajaan, Kesihatan, Tembakau',
    basicInfoProfessionMarketResearch: 'Penyelidikan Pemasaran',
    basicInfoProfessionOtherProfessions: 'Saya tidak bekerja dalam mana-mana profesion di bawah',
    basicInfoProfessionBlockedProfessions: 'Ya',
    basicInfoProfessionNotBlockedProfessions: 'Tidak',
    basicInfoProfessionBankingOrFinance: 'Perbankan/Kewangan',
    basicInfoProfessionManufacturing: 'Pembuatan',
    basicInfoProfessionTelecommunications: 'Telekomunikasi',
    basicInfoProfessionDistributionOrLogistic: 'Pengedaran/Logistik',
    basicInfoProfessionECommerceOrInformationTechnology: 'E-Dagang/Teknologi Maklumat',
    basicInfoProfessionMediaAndCommunicationsOrGovernmentOfficerOrHealthcare: 'Media & Komunikasi/Pegawai Kerajaan/Profesional Perubatan',
    basicInfoProfessionMarketResearchOrPublicRelationsOrTobacco: 'Penyelidikan Pasaran/Perhubungan Awam/Industri Tembakau',
    basicInfoProfessionSalesOrMarketing: 'Penjualan/Pemasaran',
    basicInfoProfessionOthers: 'Tiada di atas',
    basicInfoProfessionHospitalityOrFoodAndBeverage: 'Hospitaliti/F&B',
    basicInfoNicotineBrandInputLabel: 'Apakah jenama rokok yang anda paling kerap guna?',
    basicInfoNicotineFlavorInputLabel: 'Apakah varian yang anda kerap hisap?',
    basicInfoNicotineFlavorInputLabel2: 'Apakah varian yang anda kerap hisap? ',
    basicInfoSticksSmokedInputLabel: 'Berapa banyak rokok dikilangkan yang anda hisap dalam masa seminggu untuk 2 bulan yang lepas?',
    basicInfoSticksSmokedLessThanOnePerWeek: 'Kurang daripada 1 batang rokok seminggu',
    basicInfoSticksSmokedMoreThanOnePerWeek: 'Lebih daripada 1 batang rokok seminggu',
    basicInfoCurrentlyBreastfeedingInputLabel: 'Adakah anda hamil atau sedang menyusu?',
    basicInfoCurrentlyBreastfeedingYes: 'Ya',
    basicInfoCurrentlyBreastfeedingNo: 'Tidak',
    basicInfoProceedButton: 'HANTAR',
    basicInfoErrorMessage: 'Sila jawab semua soalan',

    unqualifiedMessage1: 'Harap maaf, anda tidak layak untuk turut serta dalam tinjauan ini.',
    unqualifiedMessage2: 'Terima kasih untuk masa anda.',

    answerMultipleStatusHeader: 'Thank you! Let\'s get started with the survey.',
    answerMultipleMessage1: 'Is this your first time buying Bond Street Cigarillo?',
    answerMultipleYesButton: 'YES',
    answerMultipleNoButton: 'NO',

    surveySelectDropdown: 'Pilih ...',
    surveyFillAllSurveyErrorMessage: 'Sila jawab semua soalan',
    surveyShortFormAnswerErrorMessage: 'Sila lengkapkan ruangan yang kosong',
    surveySubmitButton: 'Hantar',

    rewardsCardTnGPin: 'Pin TnG',
    rewardsCardVoucherValueText: 'NILAI BAUCAR:',
    rewardsCardVoucherExpiryDateText: 'Tarikh luput pin:',
    rewardsCopyAnsweredNoMessage1: 'Tekan',
    rewardsCopyAnsweredNoUrlLink: 'sini',
    rewardsCopyAnsweredNoMessage2: 'untuk mengetahui dengan lebih lanjut mengenai alternatif yang lebih baik untuk merokok.',
    rewardsCopyAnsweredYesMessage1: 'Tekan',
    rewardsCopyAnsweredYesUrlLink: 'sini',
    rewardsCopyAnsweredYesMessage2: 'untuk mengetahui dengan lebih lanjut mengenai IQOS dan lil.',

    rewardsNoRewardsLeftMessage1: 'Disebabkan sambutan yang menggalakkan, baucar RM2 yang diperuntukkan telah ditebus sepenuhnya.',

    generalError: 'Maaf! Masalah tidak diketahui telah berlaku. Sila cuba semula.',
    rewardAlreadyAwardedError: 'Ganjaran sudah diberi.',

    languageSelectionAndDOBHeaderContainerTitle: 'Pengesahan umur',
    languageSelectionAndDOBHeaderContainerSubTitle: 'Masukkan tarikh lahir anda di bawah untuk mengesahkan bahawa anda berumur 19 tahun atau lebih.',
    languageSelectionAndDOBheaderContainerSubTitle2: 'Dengan meneruskan, anda juga mengesahkan bahawa anda adalah seorang perokok dan faham dengan penyertaan dalam tinjaun ini adalah sukarela.',

    languageSelectionAndDOBHeaderContainerDatePick: 'PILIH TARIKH LAHIR',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderMonth: 'Bulan',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth1: 'Januari',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth2: 'Februari',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth3: 'Mac',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth4: 'April',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth5: 'Mei',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth6: 'Jun',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth7: 'Julai',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth8: 'Ogos',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth9: 'September',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth10: 'Oktober',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth11: 'November',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth12: 'Disember',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderYear: 'Tahun',
    languageSelectionAndDOBHeaderContainerMessage1: 'Saya dengan ini mengisytiharkan dan mengesahkan bahawa:',
    languageSelectionAndDOBHeaderContainerMessage2: 'Saya dengan ini juga mengisytiharkan dan mengesahkan dengan bahawa saya tidak bekerja dalam mana-mana industri berikut pada masa ini:',
    languageSelectionAndDOBHeaderContainerContent1: 'Saya berumur 19 tahun atau lebih',
    languageSelectionAndDOBHeaderContainerContent2: 'Saya tidak mengandung atau menyusu',
    languageSelectionAndDOBHeaderContainerContent3: 'Saya telah membaca dan setuju dengan',
    languageSelectionAndDOBHeaderContainerContent4: 'Saya telah membaca dan membenarkan',
    languageSelectionAndDOBHeaderContainerContent5: 'Saya merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan yang lalu',
    languageSelectionAndDOBHeaderContainerContent6: 'Saya merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan',
    languageSelectionAndDOBHeaderContainerContent7: 'Pengiklanan, Kewartawanan, Perhubungan Awam, Penyelidikan Pemasaran',
    languageSelectionAndDOBHeaderContainerContent8: 'Pegawai Kerajaan, Perundangan, Kesihatan, Industri Tembakau',
    languageSelectionAndDOBHeaderContainerContent9: 'Penyelidikan Pemasaran',
    languageSelectionAndDOBHeaderContainerTermsAndConditions: 'Terma & Syarat',
    languageSelectionAndDOBHeaderContainerPrivacyNotice: 'Notis Privasi',
    languageSelectionAndDOBHeaderContainerAnd: ' dan',
    languageSelectionAndDOBHeaderContainerDisclaimer: 'Penafian',
    languageSelectionAndDOBHeaderContainerFullStop: ' kami.',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholder: 'YYYY',
    languageSelectionAndDOBSubmitButton: 'Hantar',
    languageSelectionAndDOBTermsAndConditionsModalHeader: 'Terma & Syarat',
    languageSelectionAndDOBTermsAndConditionsModalOkButton: 'OK',
    languageSelectionAndDOBPrivacyNoticeModalHeader: 'Notis Privasi',
    languageSelectionAndDOBPrivacyNoticeModalOkButton: 'OK',
    languageSelectionAndDOBDisclaimerModalHeader: 'Penafian',
    languageSelectionAndDOBDisclaimerModalOkButton: 'OK',

    skipRewardTermsAndConditionTitle: 'Kelayakan',
    skipRewardTermsAndConditionContent1: 'Tinjauan ini hanya terbuka kepada perokok berumur sah yang:',
    skipRewardTermsAndConditionContent2: 'Berumur 19 tahun ke atas.',
    skipRewardTermsAndConditionContent3: 'Pada masa ini tidak hamil dan tidak menyusu.',
    skipRewardTermsAndConditionContent4: 'Telah merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan yang lalu.',
    skipRewardTermsAndConditionContent5: 'Tinjauan ini akan dijalankan sementara stok masih ada.',

    skipRewardDisclaimerContent1: 'Tinjaun ini tidak direka dalam apa juga cara untuk mempromosi amalan merokok dan/atau penggunaan rokok/ jenama atau produk rokok secara umum. Elakkan berbincang berkaitan tinjauan ini dalam media sosial anda atau sebarang platform mesej.',
    skipRewardDisclaimerContent2: 'Merokok adalah berbahaya dan mengakibatkan ketagihan. Sehingga kini, tiada sebarang perkara yang menyifatkan merokok adalah selamat dan tidak boleh diandaikan bahawa mana-mana jenama rokok atau varian atau format produk adalah lebih selamat daripada yang lain. Tujuan tinjauan ini bukan untuk menangani isu-isu berkaitan merokok dan kesihatan mahupun isu yang berkaitan dengan permulaan merokok, berhenti atau percubaan untuk berhenti merokok.',

    skipRewardPrivacyNoticeTitle1: 'Pengenalan',
    skipRewardPrivacyNoticeTitle2: 'Pengumpulan maklumat',
    skipRewardPrivacyNoticeTitle3: 'Penggunaan maklumat',
    skipRewardPrivacyNoticeTitle4: 'Pendedahan maklumat kepada pihak ketiga',
    skipRewardPrivacyNoticeTitle5: 'Penyimpanan maklumat',
    skipRewardPrivacyNoticeTitle6: 'Hak terhadap maklumat peribadi anda',
    skipRewardPrivacyNoticeTitle7: 'Keselamatan maklumat',
    skipRewardPrivacyNoticeTitle8: 'Penukaran terhadap Notis Privasi kami',
    skipRewardPrivacyNoticeTitle9: 'Maklumat untuk dihubungi',

    skipRewardPrivacyNoticeContent1: 'Notis Privasi ini menyatakan bahawa kami: Bike Bear Sdn Bhd mengumpul, mengguna, dan berkongsi maklumat yang dikumpulkan berhubung setiap kelengkapan dalam mana-mana tinjauan, kumpulan fokus, kajian atau aktiviti lain yang direka untuk mengumpul maklumat yang dijalankan oleh Bike Bear Sdn Bhd (secara kolektif, "Tinjauan") yang berkait atau merujuk kepada Notis Privasi ini. Dengan menjawab salah satu daripada Tinjauan ini, anda bersetuju untuk menggunakan maklumat tersebut menurut Notis Privasi ini.',
    skipRewardPrivacyNoticeContent2: 'Kami akan mengumpul maklumat peribadi mengenai anda seperti mana yang telah diberikan sebagai sebahagian daripada melengkapkan Tinjaun seperti:',
    skipRewardPrivacyNoticeContent2subContent1: 'Negara Permaustatin',
    skipRewardPrivacyNoticeContent2subContent2: 'Bangsa',
    skipRewardPrivacyNoticeContent2subContent3: 'Nombor Telefon',
    skipRewardPrivacyNoticeContent2subContent4: 'Tarikh lahir',
    skipRewardPrivacyNoticeContent2subContent5: 'Sebarang maklumat lain tentang anda yang berkaitan dengan tinjauan',
    skipRewardPrivacyNoticeContent3: 'Kami menggunakan maklumat peribadi anda yang telah dikumpul melalui Tinjauan untuk tujuan yang dinyatakan dalam Tinjauan atau di mana sahaja minat perniagaan yang sah dalam atau asas undang-undang lain untuk pengumpulan dan penggunaan. Maklumat peribadi yang telah dihantarkan kepada kami boleh digunakan untuk mengesahkan identiti anda apabila anda mengakses Tinjauan, mengurus perhubungan kami dengan anda, termasuk mana-mana permintaan, atau untuk menyesuai atau menambah baik Tinjauan kami.',
    skipRewardPrivacyNoticeContent4: 'Kami juga boleh menghubungi anda untuk mendapatkan maklum balas mengenai perkhidmatan yang disediakan atau rakan kongsi kami untuk tujuan penyelidikan. Maklumat peribadi anda juga boleh digunakan untuk melindungi hak, harta dan pengguna kami.',
    skipRewardPrivacyNoticeContent5: 'Kami mungkin memberikan maklumat peribadi anda kepada rakan kongsi kami berkaitan dengan prestasi Tinjauan, termasuk analisis data yang dikumpul dalam Tinjauan. Maklumat peribadi anda juga mungkin didedahkan kepada pihak ketiga yang lain: Philip Morris (Malaysia) Sdn Bhd; pelanggan kami yang akan membantu mereka dalam membuat keputusan tentang produk dan perkhidmatan mereka. Sila ambil perhatian bahawa adalah perlu bagi kami untuk memproses Data Peribadi anda untuk tujuan tersebut kerana tanpa proses ini anda tidak akan dapat mengambil bahagian dalam tinjauan ini.',
    skipRewardPrivacyNoticeContent6: 'Maklumat peribadi juga akan didedahkan kepada pihak berkuasa, badan kawal selia atau mana-mana agensi kerajaan, atau pihak ketiga dalam memudahkan urusan berkaitan undang-undang,  kawal selia atau mematuhi permintaan dan kewajiban berkaitan keselamatan negara.',
    skipRewardPrivacyNoticeContent7: 'Kami akan memberikan notis dan mendapatkan kebenaran anda sebelum berkongsi maklumat peribadi berkaitan anda dengan pihak ketiga yang tidak berkaitan dan tidak melaksanakan perkhidmatan kepada kami, kecuali jika perkongsian tersebut dibenarkan sebaliknya oleh Notis Privasi atau Tinjauan ini.',
    skipRewardPrivacyNoticeContent8: 'Semua yang didedahkan ini mungkin mengandungi pemindahan maklumat peribadi ke negara atau wilayah yang tidak mempunyai perlindungan data yang sama fungsi dengan tempat tinggal anda.',
    skipRewardPrivacyNoticeContent9: 'Dengan memberikan respon kepada Tinjaun ini, anda memberi kebenaran untuk pendedahan sepertinya yang dinyatakan di atas. Sebarang maklumat peribadi yang dirujuk sebagai "Pengumpulan maklumat" boleh didedahkan kepada pihak ketiga yang dikenal pasti dalam bahagian ini untuk tujuan yang dinyatakan.',
    skipRewardPrivacyNoticeContent10: 'Kami menyimpan maklumat peribadi sebagai keperluan dalam tempoh masa yang digariskan dalam  Notis Privasi. Tanpa mengira tempoh tujuan, kami mungkin menyimpan maklumat anda jika perlu untuk mematuhi kewajipan undang-undang atau profesional kami, menguatkuasakan perjanjian atau menyelesaikan sebarang pertikaian.',
    skipRewardPrivacyNoticeContent11: 'Anda mungkin mempunyai pilihan tentang bagaimana maklumat peribadi anda dikumpul, diguna dan dikongsi.',
    skipRewardPrivacyNoticeContent12: 'Anda mungkin mempunyai hak untuk meminta kami mengesahkan sama ada kami memproses maklumat peribadi anda atau kami membetulkan, memadam atau berhenti memproses maklumat peribadi anda. Anda juga mungkin mempunyai hak untuk meminta kami memberikan maklumat berikut mengenai maklumat peribadi yang kami miliki tentang anda:',
    skipRewardPrivacyNoticeContent12SubContent1: 'Kategori dan/atau maklumat peribadi spesifik yang telah kami kumpul',
    skipRewardPrivacyNoticeContent12SubContent2: 'Kategori punca maklumat peribadi dikumpul',
    skipRewardPrivacyNoticeContent12SubContent3: 'Tujuan perniagaan atau komersial untuk mengumpul maklumat peribadi',
    skipRewardPrivacyNoticeContent12SubContent4: 'Kategori pihak ketiga dengan sesiapa sahaja yang kami kongsikan maklumat peribadi',
    skipRewardPrivacyNoticeContent13: 'Untuk melaksanakan mana-mana hak anda di bawah undang-undang terpakai yang diterangkan di atas mengenai maklumat peribadi anda, sila hubungi kami di nombor ini 1-800-88-0033. Apabila menghubungi kami, sila berikan nama penuh anda, nombor telefon bimbit dan jenis permintaan khusus yang anda buat.',
    skipRewardPrivacyNoticeContent14: 'Kami telah meletakkan standard komersial yang berpatutan pada teknologi dan keselamatan operasi untuk melindungi semua maklumat peribadi yang diberikan oleh pengguna dengan menghormati Tinjauan daripada akses, pendedahan, pengubahan atau pemusnahan tanpa kebenaran.',
    skipRewardPrivacyNoticeContent15: 'Kami mungkin menambah baik atau menukar Notis Privasi ini dari masa ke semasa. Apabila kami melakukan sebarang perubahan pada Notis Privasi, kami akan menukar tarikh rujuk semula pada bahagian atas halaman dan sebarang penambahbaikan atau pertukaran Notis Privasi adalah efektif kepada anda dan maklumat anda seperti mana yang dinyatakan dalam tarikh rujukan semula. Kami menggalakkan anda untuk memeriksa semua Notis Privasi ini dari masa ke semasa untuk melihat sebarang kemas kini.',
    skipRewardPrivacyNoticeContent16: 'Jika anda mempunyai soalan atau kebimbangan mengenai privasi anda berkaitan dengan Tinjauan, sila hubungi kami di 1-800-88-0033.',

    uniqueCodeAndPhoneNumberMessage1: 'Hi, terima kasih kerana berminat untuk menyertai tinjauan ini.',
    uniqueCodeAndPhoneNumberMessage2: 'Sila masukkan kod tinjauan yang terdapat di dalam pek sleeve anda dan nombor telefon bimbit anda untuk kami menghantar kod pengesahan.',
    uniqueCodeAndPhoneNumberHowToFindUniqueCode: 'Cari kod unik anda di dalam lengan pek.',

    uniqueCodeAndPhoneNumberStep1: 'LANGKAH 1',
    uniqueCodeAndPhoneNumberStep1Message: 'Buang pelekat pada bahagian tepi pelapik pek',

    uniqueCodeAndPhoneNumberStep2: 'LANGKAH 2',
    uniqueCodeAndPhoneNumberStep2Message: 'Buang pek pelapik dan periksa dalam pelapik untuk Kod Unik tersebut',

    uniqueCodeAndPhoneNumberError1: 'Sila masukkan kod unik!',
    uniqueCodeAndPhoneNumberError2: 'Nombor telefon tidak sah!',

    uniqueCodeAndPhoneNumberErrorBlocked: `Hi! Anda telah mencapai had respons tinjauan ini.

    Kami menghargai semangat anda dalam memberi maklum-balas. Kami berharap untuk mendengar daripada anda lagi pada masa yang akan datang.

    Pendapat anda amat penting untuk kami.  
    Terima kasih!`,

    bondOtpMessage1: `Sila masukkan OTP yang telah dihantar ke +${OTPLOV.phoneNumber}`,

    bondBasicInfoFirstTimeBuyerInputLabel: 'Adakah ini kali pertama anda membeli Bond Street Cigarillo?',
    bondBasicInfoFirstTimeBuyerInputLabelSS: 'Adakah ini kali pertama anda membeli Marlboro Summer Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelTS: 'Adakah ini kali pertama anda membeli Marlboro Tropical Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelGeneric: 'Adakah ini kali pertama anda membeli',
    bondBasicInfoFirstTimeBuyerYes: 'Ya',
    bondBasicInfoFirstTimeBuyerNo: 'Tidak',

    selectLanguagePlaceholder: 'Pilih bahasa',

    invalidUniqueCodeError: 'Kod unik ini sudah diguna. Sila masukkan kod unik baharu jika anda ingin meneruskan.',

    languageSelectionAndDOBHeaderContainerAgeRange: 'Umur',

    surveyInactiveErrorMessage: `Tinjauan kami telah pun tamat.

    Kami sangat menghargai kesemua yang menyertai tinjauan ini. Maklum-balas anda sangat penting kepada kami untuk menambahbaik perkhidmatan kami.

    Kami berharap untuk mendengar daripada anda lagi pada masa yang akan datang.

    Terima kasih!
    `,

    surveyNextText: 'Seterusnya',
    surveyBackText: 'Kembali',
    surveyQuestionNoAnswerToast: 'Sila jawab soalan ini',

    languageSelectionAndDOBAgeError: 'Harap maaf, anda perlu berumur sekurang-kurangnya 19 tahun untuk menyertai tinjauan ini.',

    languageSelectionAndDOBAgreementText: 'Saya telah membaca dan bersetuju dengan {terms}, {disclaimer}, dan {privacyNotice}',

    OTPrequest: 'Minta OTP',

    goBackPromptMessage: 'Adakah anda pasti mahu kembali? Anda akan kehilangan progres anda.',
    goBackToOtpPromptMessage: 'Anda perlu memasukkan semula nombor telefon anda untuk meneruskan tinjauan ini.',

    otherBrandsLabel: 'Lain',
    surveyTitle: 'Soalan Tinjauan',

    inactivityModalTitle: 'Perhatian',
    inactivityModalQuestion: 'Adakah anda masih menggunakan halaman ini?',
    inactivityModalYesButton: 'Ya',

    rewardPS: 'Penting - Anda tidak akan dapat mengakses halaman ini lagi setelah ditutup.',
};

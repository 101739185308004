import { StylesDictionary } from 'lib/StylesDictionary';
import { css } from 'styled-components';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        background: 'white',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    questionContainer: {
        width: '100%',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
        position: 'relative',
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    optionContainerLast: {
        display: 'flex',
        alignItems: 'center',
    },
    loadingOverlay: {
        position: 'absolute',
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export const ItemStyles = {
    question: css`
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    `,
    option: css`
        color: #888888;
        font-size: 16px;
        max-width: 70%;
    `,
};

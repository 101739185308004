export interface ISurvey {
    name: string;
    description: string;
    surveyId: string;
    questions: IQuestion[];
}

export interface IQuestion {
    id: string;
    question: string;
    type: ISurveyQuestionTypeEnum;
    options: IOption[];
    image?: string[];
    answered: boolean;
    loading?: boolean;
}

export enum ISurveyQuestionTypeEnum {
    Dropdown = 1,
    SelectMultiple = 2,
    SelectOne = 3,
    SlidingThree = 4,
    SlidingTen = 5,
    ShortFormAnswer = 7
}

export interface IOption {
    id: string;
    key?: number;
    value: string;
    image?: string[];
}

export interface IDataToSubmit {
    surveyId: string;
    questionId: string;
    selectedOptionId?: string | string[];
    answer?: string;
}

export interface IDataToSubmitMultiple {
    surveyId: string;
    questionId: string;
    selectedOptionId: string[];
}

import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IOption, IDataToSubmit } from 'entities/surveys';

import icons from 'assets/icons';

import Text from 'components/Text';

import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SelectOneStyles';

interface RadioButtonProps {
    selected: boolean;
}

const RadioButton = styled.button<RadioButtonProps>`
    height: 30px;
    width: 30px;

    border: none;

    background-color: transparent;

    display: flex;
    flex-basis: 50px;
    justify-content: center;
    align-items: center;

    color: ${props => (props.selected ? '#021778' : 'white')};

    #outer {
        position: absolute;
        color: white;
        width: 30px;
        height: 30px;

        border-radius: 50%;

        border: ${props => (props.selected ? '1px solid #021778' : '1px solid #A5AAB5')};
    }

    #circle {
        position: absolute;

        width: 20px;
        height: 20px;
    }
`;

interface SelectOneProps {
    surveyId: string;
    questionId: string;
    question: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const SelectOne: FunctionComponent<SelectOneProps> = (props: SelectOneProps) => {
    const { surveyId, questionId, question, options, images, loading, changeQuestionStatus, submitAnswer } = props;

    const [selected, setSelected] = useState('');

    const radioClickHandler = (value: string) => {
        setSelected(value);
        const dataToSubmit = {
            surveyId,
            questionId,
            selectedOptionId: value,
        };

        changeQuestionStatus(questionId, true);
        // api call here!
        submitAnswer(dataToSubmit);
    };

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={images.length === 1}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                                style={{ pointerEvents: 'none' }}
                            />
                        );
                    })}
                </Grid>
            )}

            <div style={ContainerStyles.buttonsContainer}>
                {options.map((item, index) => {
                    const { value, id, image } = item;

                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(image && image.length > 0) && (
                                    image?.map(url => {
                                        return (
                                            <img
                                                src={url}
                                                alt='no img'
                                                style={{
                                                    height: '120px',
                                                    width: '120px',
                                                    marginRight: '10px',
                                                    marginTop: '25px',
                                                    marginLeft: '10px',
                                                    objectFit: 'contain',
                                                    marginBottom: '20px',
                                                    alignSelf: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        );
                                    })
                                )}
                            </div>
                            <div style={ContainerStyles.optionContainer}>
                                <RadioButton
                                    key={id}
                                    onClick={() => radioClickHandler(id)}
                                    selected={selected === id}
                                    disabled={selected === id} // so that the api wont be called when they spam click
                                >
                                    <SVG src={icons.Groove} id='outer' />
                                    <SVG src={icons.Groove} id='circle' />
                                </RadioButton>
                                <Text css={ItemStyles.option}>{value}</Text>
                            </div>
                        </div>
                    );
                })}

                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

export default connect(null, mapDispatchToProps)(SelectOne);

import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import { toast } from 'react-toastify';
import NavActions from 'lib/NavActions';
import Translate from 'i18n-js';

export default function* watchResendOTP(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeResendOTPAttempt', handleResendOTP, api);
}

function* handleResendOTP(api: HomeGateway) {
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeResendOTPFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.resendOTP], { scanId });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.name === '1406') {
            toast.error(Translate.t('otpResendFailure2') || 'Something went wrong. Please try again');
            yield put(Actions.homeResendOTPFailure(Translate.t('otpResendFailure2')));
            return;
        }
        yield put(Actions.homeResendOTPFailure(response.message));
        toast.error(response.message || Translate.t('otpResendFailure'));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('otpResendSuccess'));
        yield put(Actions.homeResendOTPSuccess());
    }
}

import React, { useEffect } from 'react';
import Translate from 'translate/Translate';
import { ContainerStyles, ItemStyles } from 'containers/home/styles';
import Text from 'components/Text';

const ErrorPhoneUsed = (): JSX.Element => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={ContainerStyles.errorContainer}>
            <Text style={{ whiteSpace: 'pre-line' }} css={ItemStyles.errorText}>
                {Translate.t('errorPhoneUsedMessage1')}
                <br />
                <br />
                {Translate.t('errorPhoneUsedMessage2')}
            </Text>
        </div>
    );
};

export default ErrorPhoneUsed;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import ReactGA from 'react-ga4';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IQuestion, ISurvey, ISurveyQuestionTypeEnum } from 'entities/surveys';
import { ILanguagePack } from 'redux/slices/home/types';

import Text from 'components/Text';
import Button from 'components/Button';
import NavActions from 'lib/NavActions';
import Translate from 'translate/Translate';

import SelectOne from './questions/SelectOne';
import SelectMultiple from './questions/SelectMultiple';
import SlidingThree from './questions/SlidingThree';
import SlidingTen from './questions/SlidingTen';

import { ContainerStyles, ItemStyles } from './styles';
import ShortFormAnswer from './questions/ShortFormAnswer';
import MultipleToggleAnswer from './questions/MultipleToggleAnswer';

interface SurveysProps {
    getSurveyLoading: boolean;
    getSurveyError: string;
    surveyData: ISurvey | null;
    scanId: string;
    languagePack: ILanguagePack;
    getAllSurveys: () => void;
}

const Surveys: FunctionComponent<SurveysProps> = (props: SurveysProps) => {
    const {
        getSurveyLoading,
        getSurveyError,
        surveyData,
        scanId,
        languagePack,
        getAllSurveys,
    } = props;
    const [questionsList, setQuestionsList] = useState<IQuestion[]>([]);
    const [surveyTitle, setSurveyTitle] = useState<string>('');
    const [stateSurveyId, setStateSurveyId] = useState('');

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (scanId) getAllSurveys();
    }, [scanId]);

    useEffect(() => {
        if (surveyData) {
            setQuestionsList(surveyData.questions);
            setStateSurveyId(surveyData.surveyId);
            setSurveyTitle(surveyData.name);
        }
    }, [surveyData]);

    const submitClickHandler = () => {
        if (surveyData) {
            const notAnswered = surveyData.questions.find(item => item.answered === false);

            if (notAnswered) {
                toast.error(Translate.t('surveyFillAllSurveyErrorMessage'));
                return;
            }

            NavActions.navToRewards();
        }
    };

    if (getSurveyLoading) {
        return (
            <div style={ContainerStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='black'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (!questionsList.length) {
        return (
            <div style={{ ...ContainerStyles.mainContainer, height: '100vh', color: 'black' }}>
                <Text>No questions found</Text>
            </div>
        );
    }

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={ContainerStyles.titleAndDescContainer}>
                <Text css={ItemStyles.title}>{surveyTitle}</Text>
                <Text css={ItemStyles.desc}>{languagePack?.surveyDisclaimer}</Text>
            </div>

            {questionsList.map(item => {
                const { type, id, question, options, image, loading } = item;

                if (type === ISurveyQuestionTypeEnum.SelectOne) {
                    return (
                        <SelectOne
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return (
                        <SelectMultiple
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SlidingThree) {
                    return (
                        <SlidingThree
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return (
                        <SlidingTen
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    );
                }

                if (type === ISurveyQuestionTypeEnum.ShortFormAnswer) {
                    return (
                        <ShortFormAnswer
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    );
                }

                return (
                    <MultipleToggleAnswer
                        key={id}
                        surveyId={stateSurveyId}
                        questionId={id}
                        question={question}
                        options={options}
                        images={image || []}
                        loading={loading || false}
                    />
                );
            })}

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '40px',
                }}
            >
                <Button onClick={submitClickHandler} css={ItemStyles.submitButton}>
                    <Text>
                        {Translate.t('surveySubmitButton')}
                    </Text>
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    getSurveyLoading: Selectors.surveyGetAllSurveysAttempting(state),
    getSurveyError: Selectors.surveyGetAllSurveysError(state),
    surveyData: Selectors.surveyGetAllSurveys(state),
    scanId: Selectors.homeGetScanId(state),
    languagePack: Selectors.homeGetLanguagePack(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAllSurveys: () => dispatch(Actions.getAllSurveysAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);

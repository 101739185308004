import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import { ISetPhoneNumberParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

export default function* watchSetPhoneNumber(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSetPhoneNumberAttempt', handleSetPhoneNumber, api);
}

function* handleSetPhoneNumber(api: HomeGateway, data: PayloadAction<ISetPhoneNumberParams>) {
    const { phoneNumber } = data.payload;
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!phoneNumber || phoneNumber.length < 10 || phoneNumber.length > 12) {
        yield put(Actions.homeSetPhoneNumberFailure('Invalid phone number.'));
        return;
    }

    if (!scanId) {
        yield put(Actions.homeSetPhoneNumberFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.setPhoneNumber], { scanId, phoneNumber });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.name === '1201') {
            yield put(Actions.homeSetPhoneNumberFailure(''));
            NavActions.navToInvalidPhone();
        } if (response.name === '1204') {
            yield put(Actions.homeSetPhoneNumberFailure(response.message || 'Something went wrong. Please try again'));
        } else if (response.name === '1205') {
            yield put(Actions.homeSetPhoneNumberFailure(''));
            NavActions.navToUnqualified();
        } else {
            if (response.name) {
                yield put(Actions.homeSetPhoneNumberFailure(''));
                yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
            } else {
                yield put(Actions.homeSetPhoneNumberFailure(''));
                yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));
            }

            NavActions.navToError();
        }
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeSetPhoneNumberSuccess({ phoneNumber }));

        NavActions.navToOTP();
    }
}

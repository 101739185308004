import { fork } from 'redux-saga/effects';

import HomeGateway from 'api/Home';
import { RootSagaReturnType } from 'sagas/types';

import SurveyGateway from 'api/Survey';
import watchTrackScan from './trackScan';
import watchSelectLanguage from './selectLanguage';
import watchSetDOB from './setDOB';
import watchSetPhoneNumber from './setPhoneNumber';
import watchSubmitOTP from './submitOTP';
import watchResendOTP from './resendOTP';
import watchSubmitBasicInfo from './submitBasicInfo';
import watchGetScanInfo from './getScanInfo';
import watchSelectLanguageAndDOB from './selectLanguageAndDOB';
import watchSubmitUniqueCodeAndPhoneNumber from './submitUniqueCodeAndPhoneNumber';
import watchSubmitBasicInfoAndSurveyStatus from './submitBasicInfoAndSurveyStatus';
import watchGetCommonCopies from './getCommonCopies';
import watchGetLanguagePack from './getLanguagePack';
import watchTrackDOB from './trackDOB';
import watchTrackDisclaimerCheckboxEngagement from './TrackDisclaimerCheckboxEngagement';

export default (api: HomeGateway, surveyApi: SurveyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchTrackScan, api, surveyApi);
        yield fork(watchSelectLanguage, api);
        yield fork(watchSetDOB, api);
        yield fork(watchSetPhoneNumber, api);
        yield fork(watchSubmitOTP, api);
        yield fork(watchResendOTP, api);
        yield fork(watchSubmitBasicInfo, api);
        yield fork(watchGetScanInfo, api);
        yield fork(watchSelectLanguageAndDOB, api);
        yield fork(watchSubmitUniqueCodeAndPhoneNumber, api);
        yield fork(watchSubmitBasicInfoAndSurveyStatus, api);
        yield fork(watchGetCommonCopies, api);
        yield fork(watchGetLanguagePack, api);
        yield fork(watchTrackDOB, api);
        yield fork(watchTrackDisclaimerCheckboxEngagement, api);
    }

    return {
        rootSaga,
    };
};

import React, { useEffect, useState } from 'react';
import { IEthnicityEnum, IFlavorEnum, IGenderEnum, INicotineBrandEnum, IProfessionEnum, IStateEnum, ISticksSmokedEnum } from 'api/HomeBase';
import ReactGA from 'react-ga4';

import Text from 'components/Text';
import Button from 'components/Button';
import Dropdown from 'containers/home/components/BasicInfoDropdown';
import LOV from 'lib/LOVs/BasicInfo';
import Translate from 'translate/Translate';

import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { ISubmitBasicInfoParams } from 'redux/slices/home/types';

import { Spinner } from 'reactstrap';
import { ContainerStyles, ItemStyles } from '../styles';

type OptionType = { [key: string]: any };

interface BasicInfoProps {
    submittingBasicInfo: boolean;
    submitBasicInfoError: string;
    phoneNumber: string;
    dob: string;
    submitBasicInfo: (params: ISubmitBasicInfoParams) => void;
}

const BasicInfo = (props: BasicInfoProps): JSX.Element => {
    const {
        submittingBasicInfo,
        submitBasicInfoError,
        phoneNumber,
        dob,
        submitBasicInfo,
    } = props;

    const [gender, setGender] = useState<IGenderEnum>();
    const [residingState, setResidingState] = useState<IStateEnum>();
    const [ethnicity, setEthnicity] = useState<IEthnicityEnum>();
    const [profession, setProfession] = useState<IProfessionEnum>(IProfessionEnum.NotBlockedProfessions);
    const [nicotineBrand, setNicotineBrand] = useState<INicotineBrandEnum>();
    const [flavor, setFlavor] = useState<IFlavorEnum>();
    const [sticksSmoked, setSticksSmoked] = useState<ISticksSmokedEnum>();
    const [currentlyBreastfeeding, setCurrentlyBreastfeeding] = useState<boolean>();

    const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
    const [professionOptions, setProfessionOptions] = useState<OptionType[]>([]);
    const [nicotineBrandOptions, setNicotineBrandOptions] = useState<OptionType[]>([]);
    const [nicotineFlavorOptions, setNicotineFlavorOptions] = useState<OptionType[]>([]);
    const [sticksSmokedOptions, setSticksSmokedOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const stateList = [];
        for (let i = 0; i < Object.keys(IStateEnum).filter(item => Number.isNaN(Number(item))).length; i += 1) {
            stateList.push({ label: Translate.t(`basicInfoResidingState${IStateEnum[i + 1]}`), value: i + 1 });
        }

        if (stateList.length) {
            setStateOptions([...stateOptions, ...stateList]);
        }
    }, [IStateEnum]);

    useEffect(() => {
        const professionList = Object.keys(IProfessionEnum).filter(key => Number.isNaN(Number(key))).map((item: string) => {
            return { label: Translate.t(`basicInfoProfession${item}`), value: IProfessionEnum[item as keyof typeof IProfessionEnum] };
        });

        if (professionList.length) {
            setProfessionOptions([...professionOptions, ...professionList]);
        }
    }, [IProfessionEnum]);

    useEffect(() => {
        const nicotineBrandList = [];
        for (let i = 0; i < LOV.NicotineBrandLOV.length; i += 1) {
            const trimmedKey = LOV.NicotineBrandLOV[i]?.replace(/\s+/g, '').replace(/&+/g, 'And');
            if (trimmedKey === INicotineBrandEnum[i + 1]) {
                nicotineBrandList.push({ label: LOV.NicotineBrandLOV[i], value: i + 1 });
            } else {
                for (let index = 0; index < Object.keys(INicotineBrandEnum).filter(item => Number.isNaN(Number(item))).length; index += 1) {
                    if (trimmedKey === INicotineBrandEnum[index + 1]) {
                        nicotineBrandList.push({ label: LOV.NicotineBrandLOV[i], value: index + 1 });
                    }
                }
            }

            if (nicotineBrandList.length) {
                setNicotineBrandOptions([...nicotineBrandOptions, ...nicotineBrandList]);
            }
        }
    }, [LOV.NicotineBrandLOV.length]);

    useEffect(() => {
        const nicotineFlavorList: OptionType[] = [];
        const nicotineFlavorIndex = Object.values(IFlavorEnum).filter(item => !Number.isNaN(Number(item)));
        if (nicotineBrand) {
            const currentBrand = LOV.NicotineFlavorLOV.find(item => item.brand === INicotineBrandEnum[nicotineBrand]);
            if (currentBrand?.flavour) {
                for (let i = 0; i < currentBrand?.flavour?.length; i += 1) {
                    const trimmedKey = currentBrand.flavour[i].replace(/\s+/g, '').replace(/&+/g, 'And');
                    Object.keys(IFlavorEnum).filter(item => Number.isNaN(Number(item))).find((item, index) => {
                        if (item === trimmedKey) {
                            nicotineFlavorList.push({ label: currentBrand.flavour[i], value: nicotineFlavorIndex[index] });
                        } return false;
                    });
                }
            }

            if (nicotineFlavorList.length) {
                setNicotineFlavorOptions(nicotineFlavorList);
            }
        }
    }, [LOV.NicotineFlavorLOV.length, nicotineBrand]);

    useEffect(() => {
        const sticksSmokedList = [];
        for (let i = 0; i < Object.keys(ISticksSmokedEnum).filter(item => Number.isNaN(Number(item))).length; i += 1) {
            sticksSmokedList.push({ label: Translate.t(`basicInfoSticksSmoked${ISticksSmokedEnum[i + 1]}`), value: i + 1 });
        }

        if (sticksSmokedList.length) {
            setSticksSmokedOptions([...sticksSmokedOptions, ...sticksSmokedList]);
        }
    }, [ISticksSmokedEnum]);

    const handleProceedClick = () => {
        if (gender
            && residingState
            && ethnicity
            && profession
            && nicotineBrand
            && flavor
            && sticksSmoked
            && currentlyBreastfeeding !== undefined
        ) {
            submitBasicInfo({ gender, state: residingState, ethnicity, profession, nicotineBrand, smokeFlavour: flavor, sticksSmoked, currentlyBreastfeeding });
        }
    };

    return (
        <div style={{ ...ContainerStyles.mainContainer, height: '100%' }}>
            <div style={{
                textAlign: 'start',
                marginTop: '40px',
                marginBottom: '40px',
                flexWrap: 'wrap',
                color: 'black',
                width: 'auto',
            }}
            >
                <div style={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    marginBottom: '30px',
                }}
                >
                    {Translate.t('basicInfoHeader')}
                </div>

                <div>
                    <Dropdown
                        onChange={(e) => {
                            setGender(e.value);
                        }}
                        value={gender ? { label: Translate.t(`basicInfoGender${IGenderEnum[gender]}`), value: gender } : undefined}
                        options={Object.keys(IGenderEnum).filter(item => Number.isNaN(Number(item))).map((key, index) => {
                            return { label: Translate.t(`basicInfoGender${key}`), value: index + 1 };
                        })}
                        placeholder={Translate.t('basicInfoGenderInputLabel')}
                    />
                </div>

                <div>
                    <Text
                        style={ItemStyles.DisabledInput}
                    >
                        {phoneNumber}
                    </Text>
                </div>

                <div>
                    <Text
                        style={ItemStyles.DisabledInput}
                    >
                        {dob}
                    </Text>
                </div>

                <div>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setResidingState(e.value);
                        }}
                        value={residingState ? { label: Translate.t(`basicInfoResidingState${IStateEnum[residingState]}`), value: residingState } : undefined}
                        options={stateOptions}
                        placeholder={Translate.t('basicInfoResidingStateInputLabel')}
                    />
                </div>

                <div>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setEthnicity(e.value);
                        }}
                        value={ethnicity ? { label: Translate.t(`basicInfoEthnicity${IEthnicityEnum[ethnicity]}`), value: ethnicity } : undefined}
                        options={Object.keys(IEthnicityEnum).filter(item => Number.isNaN(Number(item))).map((key, index) => {
                            return { label: Translate.t(`basicInfoEthnicity${key}`), value: index + 1 };
                        })}
                        placeholder={Translate.t('basicInfoEthnicityInputLabel')}
                    />
                </div>

                {/* <div>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setProfession(e.value);
                        }}
                        value={profession ? { label: Translate.t(`basicInfoProfession${IProfessionEnum[profession]}`), value: profession } : undefined}
                        options={professionOptions}
                        placeholder={Translate.t('basicInfoProfessionInputLabel')}
                    />
                </div> */}

                <div style={{ marginTop: '40px' }}>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setNicotineBrand(e.value);
                            setFlavor(undefined);
                        }}
                        value={nicotineBrandOptions.filter(item => item.value === nicotineBrand)}
                        options={nicotineBrandOptions}
                        placeholder={Translate.t('basicInfoNicotineBrandInputLabel')}
                    />
                </div>

                <div>
                    <Dropdown
                        disabled={!nicotineBrand}
                        onChange={(e: OptionType) => {
                            setFlavor(e.value);
                        }}
                        value={nicotineFlavorOptions.filter(item => item.value === flavor)}
                        options={nicotineFlavorOptions}
                        placeholder={Translate.t('basicInfoNicotineFlavorInputLabel')}
                    />
                </div>

                <div>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setSticksSmoked(e.value);
                        }}
                        value={sticksSmokedOptions.filter(item => item.value === sticksSmoked)}
                        options={sticksSmokedOptions}
                        placeholder={Translate.t('basicInfoSticksSmokedInputLabel')}
                    />
                </div>

                <div>
                    <Dropdown
                        onChange={(e: OptionType) => {
                            setCurrentlyBreastfeeding(e.value);
                        }}
                        value={[{ label: Translate.t('basicInfoCurrentlyBreastfeedingYes'), value: true }, { label: Translate.t('basicInfoCurrentlyBreastfeedingNo'), value: false }].filter(item => item.value === currentlyBreastfeeding)}
                        options={[{ label: Translate.t('basicInfoCurrentlyBreastfeedingYes'), value: true }, { label: Translate.t('basicInfoCurrentlyBreastfeedingNo'), value: false }]}
                        placeholder={Translate.t('basicInfoCurrentlyBreastfeedingInputLabel')}
                    />
                </div>
            </div>

            <div style={{ marginBottom: '40px' }}>
                <Button
                    disabled={
                        !gender
                        || !residingState
                        || !ethnicity
                        || !profession
                        || !nicotineBrand
                        || !flavor
                        || !sticksSmoked
                        || currentlyBreastfeeding === undefined
                    }
                    onClick={() => handleProceedClick()}
                    style={{
                        ...ItemStyles.ProceedButton,
                        opacity: !gender
                            || !residingState
                            || !ethnicity
                            || !profession
                            || !nicotineBrand
                            || !flavor
                            || !sticksSmoked
                            || currentlyBreastfeeding === undefined ? 0.5 : 1.0,
                        cursor: !gender
                            || !residingState
                            || !ethnicity
                            || !profession
                            || !nicotineBrand
                            || !flavor
                            || !sticksSmoked
                            || currentlyBreastfeeding === undefined ? 'not-allowed' : 'pointer',
                    }}
                >
                    {submittingBasicInfo ? <Spinner /> : Translate.t('basicInfoProceedButton')}
                </Button>

                <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{submitBasicInfoError}</Text>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submittingBasicInfo: Selectors.homeGetSubmitBasicInfoAttempting(state),
    submitBasicInfoError: Selectors.homeGetSubmitBasicInfoError(state),
    phoneNumber: Selectors.homeGetPhoneNumber(state),
    dob: Selectors.homeGetDob(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitBasicInfo: (params: ISubmitBasicInfoParams) => dispatch(Actions.homeSubmitBasicInfoAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);

import React from 'react';
import { connect } from 'react-redux';
import styled, { Keyframes, SimpleInterpolation, css, keyframes } from 'styled-components';

import Selectors from 'redux/Selectors';
import { RootState } from 'redux/store';

import Utils from 'lib/Utils';

import BondOTP from '../bond/BondOTP';
import UniqueCodeAndPhoneNumber from '../bond/UniqueCodeAndPhoneNumber';

const { Hooks: { useDelayUnmount } } = Utils;

interface OTPScreenV2Props {
    isOTPReady: boolean;
    phoneNumber: string;
}

const OTPScreenV2 = ({
    isOTPReady,
    phoneNumber,
}: OTPScreenV2Props): JSX.Element => {
    const isPhoneNumberExists = !!(phoneNumber && phoneNumber.length > 0);

    const uniqueCodeFreshLoadMotion = isPhoneNumberExists ? slideFromRightToCenter : slideFromLeftToCenter;
    const uniqueCodeEntryMotion = isOTPReady ? slideToLeft : uniqueCodeFreshLoadMotion;

    const otpFreshEntryMotion = isOTPReady ? slideFromRightToCenter : slideToRight;

    const displayOTPScreen = useDelayUnmount(isOTPReady, 1000);

    return (
        <MainContainer>
            <Container
                animation={uniqueCodeEntryMotion}
            >
                <UniqueCodeAndPhoneNumber />
            </Container>
            {displayOTPScreen && (
                <Container
                    key='otp'
                    animation={otpFreshEntryMotion}
                    css={otpStyle}
                >
                    <BondOTP />
                </Container>
            )}
        </MainContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isOTPReady: Selectors.homeIsOTPScreenReady(state),
    phoneNumber: Selectors.homeGetPhoneNumber(state),
});

const otpStyle = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
`;

const slideFromRightToCenter = keyframes`
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideToRight = keyframes`
    0%{
        transform: translateX(0);
        opacity: 1;
    }
    100%{
        transform: translateX(1000px);
        opacity: 0;
    }
`;

const slideFromLeftToCenter = keyframes`
    0% {
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideToLeft = keyframes`
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-1000px);
        opacity: 0;
    }
`;

interface ContainerProps {
    animation: Keyframes;
    css?: SimpleInterpolation;
}

const Container = styled.div<ContainerProps>`
    animation: ${props => (props.animation)} .5s both ease-in;
    ${props => props.css};
`;

const MainContainer = styled.div`
    position: relative;
`;

export default connect(mapStateToProps)(OTPScreenV2);

import { StylesDictionary } from 'lib/StylesDictionary';
import { css } from 'styled-components';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        height: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    titleAndDescContainer: {
        marginTop: '20px',
        width: '100%',
        marginBottom: '20px',
    },
    spinnerContainer: {
        height: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export const ItemStyles = {
    title: css`
        font-size: 22px;
        font-weight: bold;
        color: white;
    `,
    desc: css`
        font-size: 13px;
        color: white;
        text-align: left;
        margin-top: 10px;
        white-space: pre-line;
    `,
    submitButton: css`
        background-color: #199be1;
        color: white;

        border-radius: 0px;

        font-size: 16px;

        &:active {
            background-color: #031b7b;
        }
    `,
    navButton: css`
        background-color: #fff;
        color: 199be1;
        border-color: 199be1;
        border-radius: 0px;
        font-size: 16px;
    `,
};
